import React, { Component } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import ErrorTable from '../../components/tables/error-table/error-table';
import {
  Row,
  Col,
  CardBody,
  Container
} from "shards-react";
import Moment from 'react-moment';

import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';

import PageTitle from "../../components/common/PageTitle";

import io from 'socket.io-client';
import api from '../../services/api';

import './Errors.css';

let socket = '';

class Errors extends Component {

  constructor(props) {
    super(props);

    this.state = {
      errors: [],
      totalDataSize: 0,
      actualPage: 1,
      sizePerPage: 30,
    };
  }

  async componentDidMount() {
    // await this.loadItemData();
    this.registerToSocket();
  }


  registerToSocket = () => {
    socket = io(process.env.REACT_APP_API_URL, {
      transports:['websocket', 'polling']
    });
    socket.on('errorInsert', data => {
      var errors = [data].concat(this.state.errors);
      this.setState({ errors });
    });  
  }
  componentWillUnmount() {
    if(socket) socket.off('errorInsert');
  }


  render() {

    return (
      <Container fluid className="main-content-container px-6">

        <Row noGutters className="page-header py-4">
          <PageTitle sm="4" title="Errors" icon='lightbulb' subtitle="" className="text-left" />
        </Row>

        <div className="main errors-interno">

          <Row>
            <Col>
              <CardBody className="p-0 pb-3">
              <ErrorTable />
              </CardBody>
            </Col>
          </Row>


        </div>
      </Container>
    );
  }

}

export default Errors;
