export default function() {
	return [
		{
			title: "Dashboard",
			htmlBefore: '<i class="material-icons">dashboard</i>',
			to: "/dashboard",
		},
		{
			title: "Robôs Simulados",
			htmlBefore: '<i class="material-icons">android</i>',
			to: "/robots-simulados/0",
		}, 
		{
			title: "Robôs Reais",
			htmlBefore: '<i class="material-icons">android</i>',
			to: "/robots-reais/1",
		},
		{
			title: "Robôs Sim. Arquivados",
			htmlBefore: '<i class="material-icons">android</i>',
			to: "/robots-simul-arq/0/1",
		},
		{
			title: "Robôs Reais Arquivados",
			htmlBefore: '<i class="material-icons">android</i>',
			to: "/robots-reais-arq/1/1",
		},        
    /*
		{
			title: "Robôs",
			htmlBefore: '<i class="material-icons">android</i>',
			to: "/robots",
		},
    */
		{
			title: "Alertas",
			htmlBefore: '<i class="material-icons">timeline</i>',
			to: "/alertas",
		},
		{
			title: "Erros",
			htmlBefore: '<i class="material-icons">lightbulb</i>',
			to: "/errors",
		},
		{
			title: "Corretoras",
			htmlBefore: '<i class="material-icons">account_balance</i>',
			to: "/corretoras",
		},
		{
			title: "Coins",
			htmlBefore: '<i class="material-icons">monetization_on</i>',
			to: "/coins",
		},
		{
			title: "Usuários",
			htmlBefore: '<i class="material-icons">account_circle</i>',
			to: "/users",
		},
    /*
    {
			title: "Ações",
			htmlBefore: '<i class="material-icons">timeline</i>',
			to: "/stocks",
		},

		{
			title: "Acompanhamento",
			htmlBefore: '<i class="material-icons">timeline</i>',
			to: "/watchlist",
		},
		{
			title: "Histórico",
			htmlBefore: '<i class="material-icons">assessment</i>',
			to: "/transactions",
		},
		{
			title: "Adicionar Ação",
			htmlBefore: '<i class="material-icons">note_add</i>',
			to: "/errors",
		},
		{
			title: "Login",
			htmlBefore: '<i class="material-icons">account_circle</i>',
			to: "/login",
		},
		{
			title: "Meu Perfil",
			htmlBefore: '<i class="material-icons">account_circle</i>',
			to: "/login",
		},

		{
			title: "Errors",
			htmlBefore: '<i class="material-icons">error</i>',
			to: "/errors",
		},
    */
		{
			title: "Sair",
			htmlBefore: '<i class="material-icons">logout</i>',
			to: "/logout",
		},

	  /*
    {
      title: "Blog Dashboard",
      to: "/blog-overview",
      htmlBefore: '<i class="material-icons">edit</i>',
      htmlAfter: ""
    },
    {
      title: "Blog Posts",
      htmlBefore: '<i class="material-icons">vertical_split</i>',
      to: "/blog-posts",
    },
    {
      title: "Add New Post",
      htmlBefore: '<i class="material-icons">note_add</i>',
      to: "/add-new-post",
    },
    {
      title: "Forms & Components",
      htmlBefore: '<i class="material-icons">view_module</i>',
      to: "/components-overview",
    },
    {
      title: "Tables",
      htmlBefore: '<i class="material-icons">table_chart</i>',
      to: "/tables",
    },
    {
      title: "User Profile",
      htmlBefore: '<i class="material-icons">person</i>',
      to: "/user-profile-lite",
    },
	*/

  ];
}
