import React, { Component } from 'react';
import { Redirect } from "react-router-dom";
//import {Link} from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert'; // Import
import CurrencyFormat from 'react-currency-format';
import getAlertTypes from "../../data/alert-types";
import {
  Row,
  Col,
  Card,
//  CardHeader,
  CardBody,
  Container,
//  Label,
//  Form,
  FormInput,
  FormSelect,
  FormCheckbox,
  Alert
  
} from "shards-react";

import RobotHeader from "../../components/robotheader/RobotHeader";
import PageTitle from "../../components/common/PageTitle";
//import MainTop from "../../components/layout/MainTop/MainTop";

import api from '../../services/api';
import './RobotEdit.css';

// componente no formato de classe
class RobotEdit extends Component {

  robot = {
    _id: '',
    nome: '',
    coin: '',
    coin_id: {},
    corretora_id: {},
    ativo: '',
    tendencia: '',
    type: 'macd',
    gap: '1',
    criterios_saida: {
      ganho: {
        ativo: 1,
        percentual: ''
      },
      perda: {
        ativo: false,
        percentual: ''
      }
    },
    producao: 0,
    coins: [],
    corretoras: []
  }

	constructor(props) {
    super(props);

		this.state = {
      code: '',
      robot: this.robot,
      alert_visible: false,
      alert_msg: '',
      redirect: false,
      coins: [],
      corretoras: [],
      alertTypes: getAlertTypes()
		};

    this.alertShowHide = this.alertShowHide.bind(this);
  }

  alertShowHide(show, msg='') {
    this.setState({ alert_msg: msg, alert_visible: show });
  }

	async componentDidMount () {
		const { code } = this.props.match.params;
    this.setState({ code });

    await this.loadItemData(code);
    await this.loadDataCoins();
    await this.loadDataCorretoras();
    this.handleChangeSaida = this.handleChangeSaida.bind(this);
  }

  async loadItemData(id){
    const response = await api.get('robot/'+id);
    console.log(response.data);
    this.setState({ robot: response.data, edit: true });
  }
  async loadDataCoins(){
    const response = await api.get('coin');
    this.setState({ coins: response.data });
  }
  async loadDataCorretoras(){
    const response = await api.get('corretora');
    this.setState({ corretoras: response.data });
  }
  saveHandler = async () =>{
    //console.log(this.state.robot);
    await api.put(`robot/${this.state.robot._id}`, this.state.robot);
    this.alertShowHide(true, 'Robo salvo com sucesso.');
  }

  deleteHandler = async () =>{
    await api.delete(`robot/${this.state.robot._id}`);
    this.setState({ redirect: true});
  }
  deleteConfirm = (id) => {
    confirmAlert({
      title: 'Atenção!',
      message: "Confirma a exclusão do robô? Esta ação não pode ser desfeita.",
      buttons: [
        {
          label: 'Sim',
          onClick: () => this.deleteHandler()
        },
        {
          label: 'Não',
          onClick: () => false
        }
      ]
    });
  }
  
  arquivarHandler = async () =>{
    await api.put(`robot/arquivado/${this.state.robot._id}`,  { arquivado: 1 });
    this.setState({ redirect: true});
  }
  arquivarConfirm = (id) => {
    confirmAlert({
      title: 'Atenção!',
      message: "Tem certeza de que deseja arquivar o robô?",
      buttons: [
        {
          label: 'Sim',
          onClick: () => this.arquivarHandler()
        },
        {
          label: 'Não',
          onClick: () => false
        }
      ]
    });
  }


  handleChange = e => {
		e.persist();

    this.setState(prevState => ({
      robot: { ...prevState.robot,  [e.target.id]: e.target.value }
    }));
	}
  
  handleChangeSaida = (e, tipo) => {
    e.persist();

    let criterios_saida = this.state.robot.criterios_saida;

    if(tipo === 'ganho'){
      criterios_saida.ganho.ativo = (e.target.value === 'on' ? 1 : '0');
    
    } else if(tipo === 'ganho_percentual'){
      criterios_saida.ganho.percentual = e.target.value;

    } else if(tipo === 'perda'){
      criterios_saida.perda.ativo = (e.target.value === 'on' ? 1 : '0');

    } else if(tipo === 'perda_percentual'){
      criterios_saida.perda.percentual = e.target.value;
    }    

    this.setState(prevState => ({
      robot: { ...prevState.robot,  ['criterios_saida']: criterios_saida }
    }));

	}  
  
	render(){
		const {
			redirect,
      robot,
      alertTypes
		} = this.state;    

    if (redirect) {
      if(robot.producao == 1){
        return <Redirect to="/robots-reais/1" />;
      } else {
        return <Redirect to="/robots-simulados/0" />;
      }
    }

    return (
      <Container fluid className="main-content-container px-6">

        { /*<MainTop 
          title='Robôs'
          icon='android'
          buttons=''
        /> */ }
        <Row noGutters className="page-header py-4">
		      <PageTitle sm="4" title={robot.producao == '1' ? 'Robô Editar - Real' : 'Robô Editar'} icon='android' subtitle="" className="text-left" />
		    </Row>

        <div className="main robot-interno">
          <RobotHeader robot={robot} />

          { /*
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <h3>Moeda</h3>
                  <div className="retorno_liquido"><span className="cifrao">Código</span><br />{robot.coin}</div>
                </CardBody>
              </Card>                
            </Col>
          </Row>

          <br/>
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <Row>
                    <Col>
                      <h3>Moeda</h3>
                      <div className="retorno_liquido"><span className="cifrao">Código</span><br />{robot.coin}</div>
                    </Col>
                    <Col>
                      <h3>Moeda</h3>
                      <div className="retorno_liquido"><span className="cifrao">Código</span><br />{robot.coin}</div>
                    </Col>                    
                  </Row>
                </CardBody>
              </Card>                
            </Col>
          </Row>
          */ }
          <Card className="cardRobot">
            <CardBody>
              <Row>
                <Col>
                  <h3 htmlFor="feInputCity">Nome</h3>
                  <FormInput id="nome" type="text" placeholder=""
                    value={robot.nome}
                    onChange={this.handleChange} />
                </Col>
                <Col>
                  <h3>Capital Inicial</h3>
                  { robot.capital_inicial }
                </Col>                    
              </Row>       
              <br />
              <br />              
              <Row>
                { /*
                <Col>
                  <h3>Código da moeda</h3>
                  <FormInput id="coin" type="text" placeholder=""
                    value={robot.coin}
                    onChange={this.handleChange} />
                </Col>
                */ }
               <Col>
                  <label htmlFor="feInputAddress">Moeda</label>
                  <FormSelect id="coin_id" onChange={this.handleChange}
                    value={robot.coin_id._id} >
                    <option value=''>Selecione a moeda</option>
                    {
                    this.state.coins.map((item, idx) => (
                      <option value={item._id}>{item.nome}</option>
                    ))
                    }
                  </FormSelect>
                </Col>
                <Col>
                  <h3 htmlFor="feInputCity">Saldo Atual</h3>
                  <CurrencyFormat value={robot.saldo_atual} displayType={'text'} decimalScale={2} 
                    decimalSeparator={","}
                    thousandSeparator={"."} />
                </Col>                                   
              </Row>


              { robot.producao === 1 &&
                <div>
                  <br />
                  <br />
                  <Row>
                  <Col>
                      <label htmlFor="feInputAddress">Corretora</label>
                      <FormSelect id="corretora_id" onChange={this.handleChange}
                        value={robot.corretora_id._id} >
                        <option value=''>Selecione a corretora</option>
                        {
                        this.state.corretoras.map((item, idx) => (
                          <option value={item._id}>{item.nome}</option>
                        ))
                        }
                      </FormSelect>
                    </Col>                               
                  </Row>
                </div>
              }
            
            </CardBody>
          </Card>

          <Card className="cardRobot">
            <CardBody>
              <Row>
                <Col>
                  <h3 htmlFor="feInputCity">Tendência</h3>
                <FormSelect id="tendencia" 
                  value={robot.tendencia}
                  onChange={this.handleChange} >
                  <option value="afavor">A favor da tendência</option>
                  <option value="contra">Conta a tendência</option>
                </FormSelect>
                </Col>
                <Col>
                </Col>                    
              </Row>            
            </CardBody>
          </Card>

          <Card className="cardRobot">
            <CardBody>

            <Row className="robotRow">
                <Col>
                  <label htmlFor="feInputAddress">Type</label>
                  <FormSelect id="type" onChange={this.handleChange} value={robot.type} >
                    {
                    alertTypes.map((item, idx) => (
                      <option value={item.code}>{item.title}</option>
                    ))
                    }
                  </FormSelect>



                </Col>

                <Col>
                  <label htmlFor="feInputAddress">Gap</label>
                  <FormSelect id="gap" onChange={this.handleChange} 
                  value={robot.gap} >
                    <option value="1">1 min.</option>
                    <option value="3">3 min.</option>
                    <option value="5">5 min.</option>
                    <option value="15">15 min.</option>
                    <option value="30">30 min.</option>
                    <option value="45">45 min.</option>
                    <option value="60">1 hora</option>
                    <option value="120">2 horas</option>
                    <option value="180">3 horas</option>
                    <option value="240">4 horas</option>
                    <option value="1440">1 dia</option>
                  </FormSelect>
                </Col>
              </Row>         
            </CardBody>
          </Card>


          <Card className="cardRobot">
            <CardBody>
              <Row>
                <Col>
                  <h3 htmlFor="feInputCity">Gerenciamento de ordens</h3>
                  <label>Quantidade por ordem (Tamanho da posição)</label>
                  <FormInput id="ordens" type="text" placeholder=""
                    value={robot.ordens}
                    onChange={this.handleChange} />
                </Col>
                <Col>
                <h3 htmlFor="feInputCity">&nbsp;</h3>
                  <label>Sentido das ordens</label>
                  <FormSelect id="sentido_das_ordens"
                    onChange={this.handleChange}
                    value={robot.sentido_das_ordens}
                   >
                    <option value="compra">Apenas compra</option>
                    <option value="compra_venda">Compra e venda</option>
                    <option value="venda">Apenas venda</option>
                  </FormSelect>
                </Col>                    
              </Row>            
            </CardBody>
          </Card>

          <Card className="cardRobot">
            <CardBody>
              <Row>
                <Col>
                  <h3 htmlFor="feInputCity">Critérios de Saída</h3>
                  <label>Saídas de ganho</label>
                  <FormCheckbox
                    toggle
                    checked={robot.criterios_saida.ganho.ativo === 1}
                    onChange={(e) => this.handleChangeSaida(e, 'ganho')}>
                    Ativo
                  </FormCheckbox>
                </Col>
                <Col>
                <h3 htmlFor="feInputCity">&nbsp;</h3>
                  <label>Percentual de ganho</label>
                  <FormInput id="ganho_percentual" type="text" placeholder=""
                    value={robot.criterios_saida.ganho.percentual}
                    onChange={(e) => this.handleChangeSaida(e, 'ganho_percentual')} />
                </Col>                    
              </Row>            
              <br />
              <br />
              <Row>
                <Col>
                  <label>Saídas de perda</label>
                  <FormCheckbox
                    toggle
                    checked={robot.criterios_saida.perda.ativo === 1}
                    onChange={(e) => this.handleChangeSaida(e, 'perda')}>
                    Ativo
                  </FormCheckbox>
                </Col>
                <Col>
                  <label>Percentual de perda</label>
                  <FormInput id="perda_percentual" type="text" placeholder=""
                    value={robot.criterios_saida.perda.percentual}
                    onChange={(e) => this.handleChangeSaida(e, 'perda_percentual')} />
                </Col>                    
              </Row>            
            </CardBody>
          </Card>

          <Card>
            <CardBody>
              <Row noGutters>
                <Col>
                <Alert dismissible={(e) => this.alertShowHide(false)} open={this.state.alert_visible}>
                  { this.state.alert_msg }
                </Alert>
                </Col>
              </Row>
              <Row noGutters>
                <Col>
                  <div style={{display:'flex', justifyContent: 'space-between'}}>
                    <button className="btn btn-primary btn-sm" type="button" onClick={() => this.arquivarConfirm()}>Arquivar robô</button>                    
                    <button className="btn btn-primary btn-sm" type="button" onClick={() => this.deleteConfirm()}>Excluir robô</button>
                    <button className="btn btn-primary btn-sm" type="button" onClick={() => this.saveHandler()}>Salvar</button>
                  </div>
                </Col>
              </Row>
            
            </CardBody>
          </Card>
          
        </div>
      </Container>

    );
	}

}

export default RobotEdit;
