import React from 'react';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import CurrencyFormat from 'react-currency-format';
import Moment from 'react-moment';
import "./alert-table.css"; 


export default class AlertTable extends React.Component {
  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);
  }
  
  render() {
    return (
      <BootstrapTable
        data={this.props.alerts}
        striped
        condensed
        hover
        remote={true}
        pagination={true}
        fetchInfo={{ dataTotalSize: this.props.totalDataSize }}
        // options={ this.props.tableOptions }
        options={{
          noDataText: 'Não tem alerta ainda.',
          sizePerPage: this.props.sizePerPage,
          onPageChange: this.props.onPageChange,
          // sizePerPageList: [ 30, 50 ],
          page: this.props.currentPage,
          onSizePerPageList: this.props.onSizePerPageList
        }}
      >
        <TableHeaderColumn dataField='updatedAt' dataFormat={(date) => { return <Moment format="DD/MM/YYYY HH:mm:ss">{date}</Moment> }} isKey={true}>Data/Hora</TableHeaderColumn>
        <TableHeaderColumn dataField='code' filter={{ type: 'TextFilter', delay: 1000 }}>Coin</TableHeaderColumn>
        <TableHeaderColumn dataField='type' dataFormat={(value) => { return (value ? value.toUpperCase() : '-') }} filter={{ type: 'SelectFilter', condition: 'eq', options: this.props.types }}>Type</TableHeaderColumn>
        <TableHeaderColumn dataField='gap' filter={{ type: 'SelectFilter', condition: 'eq', options: this.props.gaps }}>GAP</TableHeaderColumn>
        <TableHeaderColumn dataField='action'>Ação</TableHeaderColumn>
        <TableHeaderColumn dataField='price' dataFormat={(value) => { return <CurrencyFormat value={value} displayType={'text'} decimalScale={5} decimalSeparator={","} thousandSeparator={"."} /> }}>Valor</TableHeaderColumn>
        <TableHeaderColumn dataField='obs'>Obs.:</TableHeaderColumn>
      </BootstrapTable>
    );
  }
}