export default function() {

  let statues = [
    {
			title: 'EMA',
			code: 'ema',
		},
    {
			title: 'MA',
			code: 'ma',
		},
    {
			title: 'MACD',
			code: 'macd',
		},
  ];

  if(process.env.REACT_APP_CODENAME == 'trade'){
    statues.push(
      {
        title: 'Super Trend',
        code: 'sp',
      }
    )
  }

	return statues;
}
