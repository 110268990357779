
import React from 'react';
import Logotipo from '../../components/logotipo/Logotipo';
import MoneyBag from '../../components/logotipo/MoneyBag';
import { userService } from '../../services/user.service';
import "./Login.css";

class Login extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      password: '',
      submitted: false,
      loading: false,
      error: '',
      version: process.env.REACT_APP_VERSION,
      codename: process.env.REACT_APP_CODENAME
    };

    this._isMounted = true;

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  async handleSubmit(e) {
    e.preventDefault();

    this.setState({ submitted: true });
    //const { email, password, returnUrl } = this.state;
    const { email, password } = this.state;

    // stop here if form is invalid
    if (!(email && password)) {
      return;
    }

    this.setState({ error: '', loading: true });

    await userService.login(email, password)
      .then(
        user => {
          console.log("logou vai redirecionar");
          //const { from } = this.props.location.state || { from: { pathname: "/" } };
          const { from } = { from: { pathname: "/robots-simulados/0" } };
          //const { from } = { from: { pathname: "/dashboard" } };
          console.log(from);
          // this.props.history.push(from);
          this.props.history.push('/robots-simulados/0');
        },
        error => this.setState({ error, loading: false })
      );

    this.setState({ loading: false });
  }

  render() {
      const { email, password, submitted, loading, error, version, codename } = this.state;
      return (

			<div className="main-content-container container-fluid px-4 my-auto h-100">
        <br />
				<div className="h-100 no-gutters row">
					<div className="auth-form mx-auto my-auto col-md-5 col-lg-3">
						<div className="card">
							<div className="card-body">
                 <Logotipo minHeight="40px" maxWidth="100%"/>
								{/* { codename === 'algotrading' && <Logotipo width="100%"/> }
                { codename === 'trade' && <MoneyBag width="50%"/> } */}
                <br />
								<h4 className="auth-form__title text-center mb-4">Acesse sua conta</h4>
								<form name="form" onSubmit={this.handleSubmit}>
									<div className={'form-group' + (submitted && !email ? ' has-error' : '')}>
										<label htmlFor="email">E-mail</label>
										<input type="text" className="form-control" name="email" value={email} onChange={this.handleChange} />
										{submitted && !email &&
											<div className="help-block">E-mail é obrigatório</div>
										}
									</div>
									<div className={'form-group' + (submitted && !password ? ' has-error' : '')}>
										<label htmlFor="password">Senha</label>
										<input type="password" className="form-control" name="password" value={password} onChange={this.handleChange} />
										{submitted && !password &&
											<div className="help-block">Senha é obrigatório</div>
										}
									</div>
									{ /*
									<div className="form-group">
										<label className="custom-control custom-checkbox">
											<input id="dr-checkbox-giXl7BqkI" type="checkbox" className="custom-control-input" />
											<label id="dr-checkbox-giXl7BqkI" className="custom-control-label" aria-hidden="true"></label>
											<span className="custom-control-description">Lembrar-me por 30 dias.</span>
										</label>
									</div>
									*/ }
                  <div className="form-group">

                    {loading &&
                      <img alt="" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                    }
                  </div>
                  {error &&
                      <div className={'alert alert-danger'}>{error}</div>
                  }
									<button className="d-table mx-auto btn btn-accent btn" disabled={loading}>Acessar sua conta</button>

                  <div>
                    <br />
                    <div className='version'>v. {version}</div>
                    <div className='version'>{codename}</div>
                  </div>
								</form>
							</div>
							{ /*
							<div className="card-footer">
								<ul className="auth-form__social-icons d-table mx-auto">
									<li><a href="#"><i className="fab fa-facebook-f"></i></a></li>
									<li><a href="#"><i className="fab fa-twitter"></i></a></li>
									<li><a href="#"><i className="fab fa-github"></i></a></li>
									<li><a href="#"><i className="fab fa-google-plus-g"></i></a></li>
								</ul>
							</div>
							*/ }
						</div>
						<div className="auth-form__meta d-flex mt-4">
							<a className="ml-auto" href="/forgotpassword">Esqueceu a senha?</a>
              { /*
							<a className="ml-auto" href="/register">Criar uma nova conta?</a>
              */ }
						</div>
					</div>
				</div>
			</div>


        );
    }
}

export default Login;
