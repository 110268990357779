import React from 'react';
//import { Switch, Route, Redirect } from "react-router-dom";
import {  Redirect } from "react-router-dom";
import withAuth from './_helpers/withAuth.js';

// Layout Types
import { DefaultLayout, ExternalLayout } from "./layouts";

import Dashboard from './pages/dashboard/Dashboard';
import Robot from './pages/robot/Robot';
import RobotGrafico from './pages/robotgrafico/RobotGrafico';
import RobotEdit from './pages/robotedit/RobotEdit';
import Robots from './pages/robots/Robots';
import RobotsArquivados from './pages/robots-arquivados/RobotsArquivados';
import Alerts from './pages/alerts/Alerts';
import Coin from './pages/coins/Coin';
import Coins from './pages/coins/Coins';
import Corretora from './pages/corretoras/Corretora';
import Corretoras from './pages/corretoras/Corretoras';
import Users from './pages/users/Users';
import User from './pages/users/User';

import ErrorPage from './pages/errors/Errors';
import Login from './pages/login/Login';
import ForgotPassword from './pages/forgotpassword/ForgotPassword';
import ResetPassword from './pages/resetpassword/ResetPassword';
import Logout from './pages/logout/Logout';
import Register from './pages/register/Register';
import UserStock from './pages/userstock/UserStock';
import Transaction from './pages/transaction/Transaction';
import Watchlist from './pages/watchlist/Watchlist';

// nao eh formato de classe, posso fazer formato de funcao mesmo

/*
function Routes(){
    return (
        <Switch>
			<Route path="/" exact component={withAuth(Stock)} layout={DefaultLayout} />
			<Route path="/login" exact component={Login} layout={DefaultLayout} />
        </Switch>
    );
}

export default Routes;
*/

export default [
  {
    path: "/",
    exact: true,
    layout: DefaultLayout,
    component: () => <Redirect to="/robots-simulados/0" />
  },
  /*
  {
    path: "/",
	exact: true,
    layout: ExternalLayout,
	componente: ErrorPage
    //component: withAuth(Stock)
  },
  */
	{ path: "/dashboard", layout: DefaultLayout, component: withAuth(Dashboard) },
	//{ path: "/robots", layout: DefaultLayout, component: withAuth(Robots) },
  { path: "/robots-simulados/:producao", layout: DefaultLayout, component: withAuth(Robots) },
  { path: "/robots-reais/:producao", layout: DefaultLayout, component: withAuth(Robots) },
  { path: "/robots-simul-arq/:producao/:arquivado", layout: DefaultLayout, component: withAuth(RobotsArquivados) },
  { path: "/robots-reais-arq/:producao/:arquivado", layout: DefaultLayout, component: withAuth(RobotsArquivados) },
	{ path: "/robot/:code", layout: DefaultLayout, component: withAuth(Robot) },
	{ path: "/robot-edit/:code", layout: DefaultLayout, component: withAuth(RobotEdit) },
	{ path: "/robot-grafico/:code", layout: DefaultLayout, component: withAuth(RobotGrafico) },
  { path: "/alertas", layout: DefaultLayout, component: withAuth(Alerts) },

  { path: "/corretoras/", exact: true, layout: DefaultLayout, component: withAuth(Corretoras) },
  { path: "/corretora/:id", layout: DefaultLayout, component: withAuth(Corretora) },
  { path: "/corretora/", layout: DefaultLayout, component: withAuth(Corretora) },

  { path: "/coins/", layout: DefaultLayout, component: withAuth(Coins) },
  { path: "/coin/", exact: true, layout: DefaultLayout, component: withAuth(Coin) },
  { path: "/coin/:id", layout: DefaultLayout, component: withAuth(Coin) },

  { path: "/users/", layout: DefaultLayout, component: withAuth(Users) },
  { path: "/user/:id", layout: DefaultLayout, component: withAuth(User) },
  { path: "/user/", layout: DefaultLayout, component: withAuth(User) },

	{ path: "/stocks", layout: DefaultLayout, component: withAuth(UserStock) },
	{ path: "/watchlist", layout: DefaultLayout, component: withAuth(Watchlist) },
  { path: "/transactions", layout: DefaultLayout, component: withAuth(Transaction) },

	{ path: "/errors", layout: DefaultLayout, component: ErrorPage },
	{ path: "/login", layout: ExternalLayout, component: Login },
	{ path: "/forgotpassword", layout: ExternalLayout, component: ForgotPassword},
	{ path: "/resetpassword/:code", layout: ExternalLayout, component: ResetPassword},
	{ path: "/logout", layout: ExternalLayout, component: Logout,},
	{ path: "/register", layout: ExternalLayout, component: Register,},

];
