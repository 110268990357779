import React from 'react';
import StockCodeField from "../stockcodefield/StockCodeField";
import './StockAddModal.css';
import {
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  Form,
  FormInput,
  FormGroup,
  Button
} from "shards-react";

const StockAddModal = (props) => {


	return (
		<div className='myModal'>
            <div className="modal-wrapper"
                style={{
                    transform: props.show ? 'translateY(0vh)' : 'translateY(-100vh)',
                    opacity: props.show ? '1' : '0'
                }}>
                <div className="modal-header">
                    <h3>Adicionar Ação</h3>
                    <span className="close-modal-btn" onClick={props.close}>×</span>
                </div>
                <div className="modal-body">
					<ListGroup flush>
					    <ListGroupItem className="p-3">
					      <Row>
					        <Col>
					          <Form id='stockForm'>
								  { /*}
								<FormGroup>
									<label htmlFor="feInputAddress">Código da Ação</label>
									<FormInput id="code" type="text" placeholder="Código"
										onChange={props.handleChange} />
								</FormGroup>
  								<FormGroup>
  									<label htmlFor="feInputAddress">Nome</label>
  									<FormInput id="name" type="text" readOnly="readOnly" placeholder="Nome"
										/>
  								</FormGroup>
								*/ }
								<StockCodeField handleChange={props.handleChange} setCode={props.setCode}></StockCodeField>


					            <Row form>
					              <Col md="6" className="form-group">
					                <label htmlFor="feInputCity">Unidades</label>
					                <FormInput id="units" type="number" placeholder="Unidades"
										onChange={props.handleChange}  />
					              </Col>
					              <Col md="6" className="form-group">
					                <label htmlFor="feInputState">Preço</label>
					                <FormInput id="unit_value" type="number" placeholder="Preço"
										onChange={props.handleChange}  />
					              </Col>
					            </Row>

								<FormGroup>
									<label htmlFor="feInputAddress">Data da compra</label>
									<FormInput id="date" type="date" placeholder="dd/mm/yyyy"
										onChange={props.handleChange}  />
								</FormGroup>

					            <Button type="button" onClick={props.add}>Adicionar Nova Ação</Button>
					          </Form>
					        </Col>
					      </Row>
					    </ListGroupItem>
					  </ListGroup>
				</div>

            </div>
        </div>
	)

}

export default StockAddModal;
