import React, { Component } from 'react';
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
//  Navbar,
} from "shards-react";

//import io from 'socket.io-client';
import api from '../../services/api';

import PageTitle from "../../components/common/PageTitle";

import './Dashboard.css';


// componente no formato de classe
class Dashboard extends Component {


  constructor(props) {

    console.log(props);
    super(props);

    this.state = {
      data: {},
      showMenu : false, // by default to disable it
    };
  }

  async componentDidMount() {
    await this.loadData();
  }
  async loadData() {
    const response = await api.get('dashboard');
    this.setState({ data: response.data });
  }
  render() {
    const {
      data
    } = this.state;

    return (
      <Container fluid className="main-content-container px-6">
        <Row noGutters className="page-header py-4">
          <PageTitle sm="4" title="Dashboard" icon='dashboard' subtitle="" className="text-left" />
        </Row>
        <br />
        <br />
        <div className="dashboard">
          <Row>
            <Col>
              <Card className="info">
                <CardHeader>
                  <h2>Trades em aberto</h2>
                </CardHeader>
                <CardBody>
                  <div className="valores">{data.trades_abertos}</div>
                </CardBody>
              </Card>
            </Col>
            <Col>
              <Card className="info">
                <CardHeader>
                <h2>Total investido</h2>
                </CardHeader>
                <CardBody>
                <div className="valores">{data.total_investido}</div>
                </CardBody>
              </Card>

            </Col>
            { /* <Col className="text-right"> */ }
            <Col>
              <Card className="info">
                <CardHeader>
                  <h2>Saldo Total</h2>
                </CardHeader>
                <CardBody>
                <div className="valores">{data.saldo_total}</div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>

      </Container>
    );
  }

}

export default Dashboard;
