import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {
  Row,
  Col,
//  Card,
//  CardHeader,
  CardBody,
  Container
} from "shards-react";
//import Moment from 'react-moment';

import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';

import PageTitle from "../../components/common/PageTitle";
//import RobotHeader from "../../components/robotheader/RobotHeader";

//import io from 'socket.io-client';
import api from '../../services/api';

import './Coins.css';

const tableOptions = {
  noDataText: 'Não tem coin ainda.',
  sizePerPage: 30,
  /*
  onRowClick: function(row) {
    //alert(`You click row id: ${row._id}`);
    this.goToInternalPage(row._id);
  },
  */
  /*
  onRowDoubleClick: function(row) {
    alert(`You double click row id: ${row.id}`);
  }
  */
};

class Coins extends Component {

  
  constructor(props) {
    super(props);

    this.state = {
      coins: []
    };
  }

  async componentDidMount() {
    await this.loadItemData();
  }

  async loadItemData() {
    const response = await api.get('coin');
    this.setState({ coins: response.data });
  }

  getAcoes(cell, row){
    return (
      <Link to={`/coin/${row._id}`}>
        <button className="btn btn-secondary btn-sm" type="button">Editar</button>
      </Link>
    );
  }

  render() {
    const {
      coins
    } = this.state;

    return (
      <Container fluid className="main-content-container px-6">

        <Row noGutters className="page-header py-4">
		      <PageTitle sm="4" title="Coins" icon='monetization_on' subtitle="" className="text-left" />
          <Col>
            <div style={{textAlign:'right'}}>
              <Link to={`/coin/`}>
                <button className="btn btn-secondary btn-sm" type="button">Adicionar Coin</button>
              </Link>
            </div>
          </Col>
		    </Row>

        <div className="main coins-interno">

          <Row>
            <Col>
              <CardBody className="p-0 pb-3">

              <BootstrapTable data={ coins } striped condensed hover pagination options={ tableOptions }>
                <TableHeaderColumn dataField='nome' isKey={ true }>Nome</TableHeaderColumn>
                <TableHeaderColumn dataField='binance_code'>Binance CODE</TableHeaderColumn>
                { /*
                <TableHeaderColumn dataField='mercado_bitcoin_code'>Mercado Bitcoin CODE</TableHeaderColumn>
                <TableHeaderColumn dataField='trading_view_code'>Trading View CODE</TableHeaderColumn>
                */ }
                <TableHeaderColumn dataField='_id' dataFormat={ this.getAcoes }>Ações</TableHeaderColumn>
              </BootstrapTable>

              </CardBody>
            </Col>
          </Row>


        </div>
      </Container>

    );
  }

}

export default Coins;
