import React, { Component } from 'react';

import {
  Col,
  Container,
  FormInput,
  Row
} from "shards-react";

import { confirmAlert } from 'react-confirm-alert'; // Import
import PageTitle from "../../components/common/PageTitle";
import RobotAddModal from '../../components/robotaddmodal/RobotAddModal';

import io from 'socket.io-client';
import api from '../../services/api';

//import CurrencyFormat from 'react-currency-format';
//import SmallStats from "../../components/common/SmallStats";
//import MainTop from "../../components/layout/MainTop/MainTop";
import Robot from "../../components/robot/Robot";
import './Robots.css';

let socket = '';

// componente no formato de classe
class Robots extends Component {

	constructor(props) {
    super(props);

		this.state = {
      producao: 0,
			robots_full: [],
			robots: [],
      corretoras: [],
      coins: [],
      robot: {
        nome: '',
        coin: '',
        corretora: '',
        capital_inicial: '',
        saldo_atual: '0',
        posicao: '0',
        producao: '0',
        tendencia: 'afavor',
        ativo: '0',
        ordens: '1',
        type: 'macd',
        gap: '1',
        sentido_das_ordens: 'compra_venda',
        criterios_saida: {
          ganho: {
            ativo: 1,
            percentual: '30'
          },
          perda: {
            ativo: 1,
            percentual: '2'
          }
        }
      }
		};

  }

	async componentDidMount() {
    const { producao } = this.props.match.params;
    this.setState({ producao });

    //await this.loadData();
    await this.loadData(producao);
    await this.loadDataCorretoras();
    await this.loadDataCoins();
    this.registerToSocket();
  }  

	async componentDidUmount() {
    if(socket) socket.off('robotUpdate');
  }

  registerToSocket = () => {
    //const socket = io(process.env.REACT_APP_API_URL);
    socket = io(process.env.REACT_APP_API_URL, {
      transports:['websocket', 'polling']
    });
    socket.on('robotUpdate', data => {
      //this.setState({ robot: data });

      this.setState({
        robots: this.state.robots.map(robot =>
          robot._id === data._id ? data : robot,
        )
      })

    });
  }
  componentWillUnmount() {
    if(socket) socket.off('alertInsert');
  }
  async loadData(producao){
    //const response = await api.get('robot');
    const response = await api.get(`robots/${producao}`);
    let robots = response.data;
    this.setState({ robots: robots, robots_full: robots });
  }

  async loadDataCorretoras(){
    const response = await api.get('corretora');
    this.setState({ corretoras: response.data });
  }

  async loadDataCoins(){
    const response = await api.get('coin');
    this.setState({ coins: response.data });
  }

  addRobotHandler = async () => {
    //console.log(this.state.robot);
    let robot = this.state.robot;
    
    // nao precisa, agora é feito no backend
    //robot.saldo_atual = robot.capital_inicial;

    if(!robot.capital_inicial){
      robot.capital_inicial = 0;
    }

    if(robot.producao === '0'){
      robot.corretora = '';
    }

    await api.post('/robot/', robot);
    this.closeModalHandler();
    //await this.loadData();
    await this.loadData(this.state.producao);
  }

  changeStatus = async (id, status) => {
    await api.put(`robot/status/${id}`, { ativo: status });
    //await this.loadData();
    await this.loadData(this.state.producao);
  }  

  handleChange = e => {
    let robot = this.state.robot;

    robot = { ...robot, [e.target.id]: e.target.value };

    /* isso era quando o campo era radiobutton - agora funciona igual aos demais campos
    if(e.target.name == 'producao'){
      robot = { ...robot, [e.target.name]: (e.target.value) };
    } else {
      robot = { ...robot, [e.target.id]: e.target.value };
    }
    */

    this.setState({robot});
  }

  setCode = code => {
    this.setState({ code });
  }

  openModalAddRobot = async () => {
    await this.setState({
      isShowing: true,
    });
  }
  closeModalHandler = async () => {
    await this.setState({
      isShowing: false,
    });
  }

  handleBusca = e => {

    let valor = e.target.value;
    let robots = this.state.robots_full;

    if(valor){
      let robots_filter = robots.filter(function(item){
        //return item.nome == e.target.value;
        //return item.nome == "001 ADA MA 60";
        let nome = item.nome.toLowerCase();
        return nome.includes(valor.toLowerCase());
      }).map(function(item){
        return item;
      });
      console.log(robots_filter);
      this.setState({robots: robots_filter});
    } else {
      this.setState({robots});
    }
  }

  
  // duplicar robo- inicio
  cloneRobot = async (id) => {
    let response = await api.post(`robot/clone/${id}`);

    console.log(response);
    
    if(response.data.ok === true){
      const id = response.data.clone._id;
      this.props.history.push(`/robot-edit/${id}`);
    } else {
      console.log(`Erro: ${response.data.msg}`);
    }
  }  
  cloneRobotConfirm = (id) => {
    confirmAlert({
      title: 'Atenção!',
      message: "Confirma a duplicação do robô?",
      buttons: [
        {
          label: 'Sim',
          onClick: () => this.cloneRobot(id),
          className: 'buttonYes'
        },
        {
          label: 'Não',
          onClick: () => false,
          className: 'buttonNo'
        }
      ]
    });
  }
  // duplicar robo - fim
	render(){
		const {
			robots,
      //open
		} = this.state;    

    return (
      <Container fluid className="main-content-container px-6">
        { /*
        <MainTop 
          title='Robôs'
          icon='android'
          right=''
        />
        */ }

        <Row noGutters className="page-header py-4">
		      <PageTitle sm="4" title={this.state.producao === '1' ? 'Robôs Reais' : 'Robôs Simulados'} icon='android' subtitle="" className="text-left" />
          <Col>
            <div style={{textAlign:'right'}}>
              <FormInput id="busca" type="text" placeholder="Busca" onChange={this.handleBusca} />
            </div>
          </Col>
          <Col>
            <div style={{textAlign:'right'}}>
              <button className="btn btn-secondary btn-sm" type="button" onClick={() => this.openModalAddRobot()}>Adicionar Robô</button>
            </div>
          </Col>
		    </Row>

        <div className="main">
          <Row>
           {robots.map((item, idx) => (
              <Col lg={4} md={6} sm={12} key={item._id}>
                <Robot
                  config={item}
                  changeStatus={this.changeStatus} 
                  cloneRobotConfirm={this.cloneRobotConfirm}
                />
                <br />
                <br />
              </Col>
            ))}              
          </Row>
        </div>
      

        <RobotAddModal
          className="modal"
          show={this.state.isShowing}
          close={this.closeModalHandler}
          add={this.addRobotHandler}
          handleChange={this.handleChange}
          setCode={this.setCode}
          vars={this.state}
          aria-labelledby="contained-modal-title-vcenter">
        </RobotAddModal>

      </Container>

    );
	}

}

export default Robots;
