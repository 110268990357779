import React, { Component } from 'react';
import { Container, Row, Col, Card, CardBody } from "shards-react";
import CurrencyFormat from 'react-currency-format';

import io from 'socket.io-client';
import api from '../../services/api';

import PageTitle from "../../components/common/PageTitle";
import StockwatchAddModal from '../../components/stockwatchaddmodal/StockwatchAddModal';
import './Watchlist.css';


// componente no formato de classe
class Watchlist extends Component {

  constructor(props) {
    super(props);

    this.state = {
      Watchlist: [],
      code: null,
      fall_below: null,
      rise_above: null
    };

  }

  async componentDidMount() {
    await this.loadData();
    //this.registerToSocket();
  }

  async loadData() {
    const response = await api.get('stockwatch');
    this.setState({ Watchlist: response.data });
  }

  handleAdd = async () => {
    await this.setState({
      isShowing: true,
    });
  }
  closeModalHandler = async () => {
    await this.setState({
      isShowing: false,
    });
  }

  // adiciona acao para a watchlist
  addStockhandler = async () => {
    let stock = {
      code: this.state.code,
      fall_below: this.state.fall_below,
      rise_above: this.state.rise_above
    }

    await api.post('/stockwatch/', stock);

    this.closeModalHandler();

    await this.loadData();
  }

  // remove a acao
  handleRemove = (idx, id) => {
    api.delete(`/stockwatch/${id}/`);
    let array = this.state.Watchlist;
    array.splice(idx, 1);
    this.setState({ Watchlist: array });
  }

  // monitora mudancas no valor do form
  handleChange = e => {
    this.setState({ [e.target.id]: e.target.value });
  }

  setCode = code => {
    this.setState({ code });
  }

  registerToSocket = () => {
    console.log("registerToSocket");
    const socket = io('http://localhost:3333');

    socket.on('stocks', stocks => {
      // adiciona o post no inicio da lista
      this.setState({ Stocks: stocks });
      //this.setState({ feed: [newPost, ...this.state.feed] });
      this.startAll();
    });

    socket.on('stockWatch', stockChange => {
      //console.log(stockChange);

      this.setState({
        Watchlist: this.state.Watchlist.map(stock =>
          stock.code === stockChange.symbol ? this.updateStockValues(stockChange, stock) : stock,
        )
      })

    });
  }

  updateStockValues(stockChange, stock) {
    stock.regularMarketPrice = stockChange.regularMarketPrice;
    stock.chartPreviousClose = stockChange.chartPreviousClose;
    stock.minMarketPrice = stockChange.minMarketPrice;
    stock.maxMarketPrice = stockChange.maxMarketPrice;
    stock.open = stockChange.open;
    return stock;
  }

  render() {
    const {
      Watchlist
    } = this.state;

    return (
      <Container fluid className="main-content-container px-6">
        <StockwatchAddModal
          className="modal"
          show={this.state.isShowing}
          close={this.closeModalHandler}
          add={this.addStockhandler}
          handleChange={this.handleChange}
          setCode={this.setCode}
          vars={this.state}
          aria-labelledby="contained-modal-title-vcenter">
        </StockwatchAddModal>

        {/* Page Header */}
        <section id='watchlist'>
          <Row noGutters className="page-header py-4">
            <PageTitle sm="4" title="Acompanhamento" subtitle="" className="text-sm-left" />
          </Row>
          <Row noGutters className="page-header py-4">
            <Col>
              <div style={{ textAlign: 'right' }}>
                <button className="btn btn-primary btn-sm" type="button" onClick={() => this.handleAdd()}>adicionar</button>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <Card small className="mb-4">

                <CardBody className="p-0 pb-3">
                  <table className="table mb-0">
                    <thead className="bg-light">
                      <tr>
                        <th width={'10%'} scope="col-" className="border-0">Ação</th>
                        <th width={'10%'} scope="col" className="border-0">Anterior</th>
                        <th width={'10%'} scope="col" className="border-0">Abertura</th>
                        <th width={'10%'} scope="col" className="border-0">Valor Atual</th>
                        <th width={'10%'} scope="col" className="border-0">Máxima</th>
                        <th width={'10%'} scope="col" className="border-0">Mínima</th>
                        <th width={'5%'} scope="col" className="border-0">Var.</th>
                        <th width={'5%'} scope="col" className="border-0">Var.%</th>
                        <th width={'5%'} scope="col" className="border-0">Baixar a</th>
                        <th width={'5%'} scope="col" className="border-0">Subir a</th>
                        <th width={'15%'} scope="col" className="border-0">Ações</th>
                      </tr>
                    </thead>
                    <tbody>
                      {Watchlist.map((stock, idx) => (
                        <tr key={idx}>
                          <td>{stock.code}</td>
                          <td>{!stock.previousClose  || <CurrencyFormat value={stock.previousClose} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'R$ '} />}</td>
                          <td>{!stock.open  || <CurrencyFormat value={stock.open} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'R$ '} />}</td>
                          <td>{!stock.regularMarketPrice  || <CurrencyFormat value={stock.regularMarketPrice} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'R$ '} />}</td>
                          <td>{!stock.maxMarketPrice  || <CurrencyFormat value={stock.maxMarketPrice} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'R$ '} />}</td>
                          <td>{!stock.minMarketPrice  || <CurrencyFormat value={stock.minMarketPrice} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'R$ '} />}</td>

                          <td><CurrencyFormat value={stock.regularMarketPrice - stock.previousClose} displayType={'text'} decimalScale={2} thousandSeparator={true} /></td>
                          <td><CurrencyFormat value={((stock.regularMarketPrice - stock.previousClose) / stock.regularMarketPrice) * 100} displayType={'text'} decimalScale={2} thousandSeparator={true} /></td>

                          <td>
                            {!stock.fall_below  || <CurrencyFormat value={stock.fall_below} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'R$ '} />}
                          </td>
                          <td>
                            {!stock.rise_above  || <CurrencyFormat value={stock.rise_above} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'R$ '} />}
                          </td>

                          <td><button className="btn btn-primary btn-sm" type="button" onClick={() => this.handleEdit(idx, stock._id)}>editar</button>
							  &nbsp;<button className="btn btn-danger btn-sm" type="button" onClick={() => this.handleRemove(idx, stock._id)}>remover</button></td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </section>
      </Container>

    );
  }

}

export default Watchlist;
