import React from 'react';
//import CoinCodeField from "../coincodefield/CoinCodeField";
import getAlertTypes from "../../data/alert-types";
import './RobotAddModal.css';
import {
//  ListGroup,
//  ListGroupItem,
  Row,
  Col,
  Form,
  FormInput,
//  FormRadio,
  FormSelect,
  Button,
//  FormCheckbox
} from "shards-react";

import { Modal, ModalBody, ModalHeader } from "shards-react";


// const RobotAddModal = (props) => {
export default class RobotAddModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      alertTypes: getAlertTypes(),
    }
  }

  render() {
    const props = this.props;
    return (
      <div>
        <Modal className="modalRobot" open={props.show} toggle={props.close}>
          <ModalHeader>
            Adicionar Robô
          </ModalHeader>
          <ModalBody>
              <Form id='stockForm'>
                <Row className="robotRow">
                  <Col>
                    <label htmlFor="feInputAddress">Nome</label>
                    <FormInput id="nome" type="text"
                      onChange={props.handleChange} />
                  </Col>
                </Row>
  
                <Row className="robotRow">
                  { /*
                  <Col>
                    <label htmlFor="feInputAddress">Moeda (código)</label>
                    <FormInput id="coin" type="text"
                      onChange={props.handleChange} />          
                  </Col>
                  */ }

                  <Col>
                    <label htmlFor="feInputAddress">Moeda</label>
                    <FormSelect id="coin_id" onChange={props.handleChange} >
                      <option value=''>Selecione a moeda</option>
                      {
                      props.vars.coins.map((item, idx) => (
                        <option value={item._id}>{item.nome}</option>
                      ))
                      }
                    </FormSelect>
                  </Col>

                  <Col>
                    <label htmlFor="feInputAddress">Capital Inicial</label>
                    <FormInput id="capital_inicial" type="text"
                      onChange={props.handleChange} />
                  </Col>
                </Row>

                <Row className="robotRow">
                  <Col>
                    <label htmlFor="feInputAddress">Type</label>
                    <FormSelect id="type" onChange={props.handleChange} >
                      {
                      this.state.alertTypes.map((item, idx) => (
                        <option value={item.code}>{item.title}</option>
                      ))
                      }

                    </FormSelect>

                  </Col>

                  <Col>
                    <label htmlFor="feInputAddress">Gap</label>
                    <FormSelect id="gap" onChange={props.handleChange} >
                      <option value="1">1 min.</option>
                      <option value="3">3 min.</option>
                      <option value="5">5 min.</option>
                      <option value="15">15 min.</option>
                      <option value="30">30 min.</option>
                      <option value="45">45 min.</option>
                      <option value="60">1 hora</option>
                      <option value="120">2 horas</option>
                      <option value="180">3 horas</option>
                      <option value="240">4 horas</option>
                      <option value="1440">1 dia</option>
                    </FormSelect>
                  </Col>
                </Row>


                <Row className="robotRow">
                  <Col>
                    <label htmlFor="feInputAddress">Tendência</label>
                    <FormSelect id="tendencia" onChange={props.handleChange} >
                      <option value="afavor">A favor da tendência</option>
                      <option value="contra">Conta a tendência</option>
                    </FormSelect>
                  </Col>

                  <Col>
                    <label htmlFor="feInputAddress">Modo real</label>
                    <FormSelect id="producao" onChange={props.handleChange} >
                      <option value="0">Não</option>
                      <option value="1">Sim</option>
                    </FormSelect>

                  </Col>
                </Row>
                <Row className="robotRow">
                  { props.vars.robot.producao === '1' &&
                  <Col>
                    <label htmlFor="feInputAddress">Corretora</label>
                    <FormSelect id="corretora_id" onChange={props.handleChange} >
                    <option value=''>Selecione a corretora</option>
                    {
                    props.vars.corretoras.map((item, idx) => (
                      <option value={item._id}>{item.nome}</option>
                    ))
                    }

                    </FormSelect>

                  </Col>
                  }
                </Row>

                <Row className="robotRow">
                  <Col>
                    <Button type="button" className="btn-save" onClick={props.add}>Criar robô</Button>
                  </Col>
                </Row>
              </Form>
          </ModalBody>
        </Modal>
      </div>
    )
  }

}

// export default RobotAddModal;
