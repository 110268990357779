import React, { Component } from 'react';
import { Link, Redirect } from "react-router-dom";

import {
  Row,
  Col,
  Card,
//  CardHeader,
  CardBody,
  Container,
//  Label,
//  Form,
  FormInput,
//  FormSelect,
//  FormCheckbox,
  Alert
  
} from "shards-react";

//import Moment from 'react-moment';
//import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';

import PageTitle from "../../components/common/PageTitle";
//import RobotHeader from "../../components/robotheader/RobotHeader";

//import io from 'socket.io-client';
import api from '../../services/api';

import './Coins.css';

class Coins extends Component {


  constructor(props) {
    super(props);

    this.state = {
      currentItem: {},
      alert_visible: false,
      alert_msg: '',
      redirect: false
    };

  }
  async componentDidMount() {
    const { id } = this.props.match.params;
    if(id){
      await this.loadItemData(id);
    }
  }

  async loadItemData(id) {
    const response = await api.get('coin/'+id);
    this.setState({ currentItem: response.data, edit: true });
  }
  alertShowHide(show, msg='') {
    this.setState({ alert_msg: msg, alert_visible: show });
  }
  saveHandler = async () =>{
    if(this.state.currentItem._id){
      api.put(`coin/${this.state.currentItem._id}`, this.state.currentItem).then(
        data => {
          this.alertShowHide(true, 'Coin salva com sucesso.');
        },
        error => {
          this.alertShowHide(true, 'Erro: Confira todos os campos obrigatorios');
        }
      );
    } else {
      api.post(`coin`, this.state.currentItem).then(
        data => {
          this.alertShowHide(true, 'Coin cadastrada com sucesso.');
          this.setState({ redirect: true});
        },
        error => {
          this.alertShowHide(true, 'Erro: Confira todos os campos obrigatorios');
        }
      );
    }
  }

  deleteHandler = async () =>{
    await api.delete(`coin/${this.state.currentItem._id}`);
    this.setState({ redirect: true});
  }


  handleChange = e => {
		e.persist();

    this.setState(prevState => ({
      currentItem: { ...prevState.currentItem,  [e.target.id]: e.target.value }
    }));
	}

  render() {
    const {
      currentItem,
      redirect
    } = this.state;

    if (redirect) {
      return <Redirect to="/coins" />;
    }

    return (
      <Container fluid className="main-content-container px-6">

        <Row noGutters className="page-header py-4">
		      <PageTitle sm="4" title={ (currentItem._id ? "Alterar coin" : "Adicionar coin") } icon='monetization_on' subtitle="" className="text-left" />
          <Col>
          </Col>
		    </Row>

        <div className="main coins-interno">

          <Row>
            <Col>
              <Card className="cardCoin">
                <CardBody>
                  <Row>
                  <Col>
                      <h3 htmlFor="feInputCity">Nome *</h3>
                      <FormInput id="nome" type="text" placeholder=""
                        value={currentItem.nome}
                        onChange={this.handleChange} />
                    </Col> 
                    <Col>
                      <h3 htmlFor="feInputCity">Binance CODE *</h3>
                      <FormInput id="binance_code" type="text" placeholder=""
                        value={currentItem.binance_code}
                        onChange={this.handleChange} />
                    </Col>                
                  </Row> 
                  <br />
                  <br /> 
                  { /* 
                  <Row>
                    <Col>
                      <h3 htmlFor="feInputCity">Mercado Bitcoin CODE *</h3>
                      <FormInput id="mercado_bitcoin_code" type="text" placeholder=""
                        value={currentItem.mercado_bitcoin_code}
                        onChange={this.handleChange} />
                    </Col>                
                    <Col>
                      <h3 htmlFor="feInputCity">Trading View CODE *</h3>
                      <FormInput id="trading_view_code" type="text" placeholder=""
                        value={currentItem.trading_view_code}
                        onChange={this.handleChange} />
                    </Col>                
                  </Row>       
                  <br />
                  <br />     
                  */ }              
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Card>
            <CardBody>
              <Row noGutters>
                <Col>
                <Alert dismissible={(e) => this.alertShowHide(false)} open={this.state.alert_visible}>
                  { this.state.alert_msg }
                </Alert>
                </Col>
              </Row>
              <Row noGutters>
                <Col>
                  <div style={{display:'flex', justifyContent: 'space-between'}}>
                    <button className="btn btn-primary btn-sm" type="button" onClick={() => this.deleteHandler()}>Excluir coin</button>

                    <div>
                      <Link to={`/coins`}>
                        <button className="btn btn-primary btn-sm" type="button">Voltar</button>
                      </Link>
                      &nbsp;&nbsp;&nbsp;
                      <button className="btn btn-primary btn-sm" type="button" onClick={() => this.saveHandler()}>Salvar</button>
                    </div>
                  </div>
                </Col>
              </Row>
            
            </CardBody>
          </Card>

        </div>
      </Container>

    );
  }

}

export default Coins;
