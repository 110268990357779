import React, { Component } from 'react';
import CurrencyFormat from 'react-currency-format';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import OrdersTable from '../../components/tables/order-table/order-table';

import $ from 'jquery';
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Container,
  Badge,
  Button
} from "shards-react";
import Moment from 'react-moment';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import PageTitle from "../../components/common/PageTitle";
import RobotHeader from "../../components/robotheader/RobotHeader";

import io from 'socket.io-client';
import api from '../../services/api';

import './Robot.css';
//import url from 'socket.io-client/lib/url';

let socket;

// componente no formato de classe
class Robot extends Component {
  intervalID = 0;

  robot = {
    _id: '',
    nome: '',
    coin: '',
    coin_id: {},
    ativo: '',
    posicao: '',
  }

  constructor(props) {
    super(props);

    this.state = {
      code: '',
      robot: this.robot,
      ordens: [],
      price: '',
      price_total: '',
      totalDataSize: 0,
      actualPage: 1,
      sizePerPage: 30,
      last_order: null, 
      variacao: ''
    };

  }

  async componentDidMount() {
    const { code } = this.props.match.params;
    this.setState({ code });
    await this.loadItemData(code);
    await this.loadOrdens();
    this.registerToSocket();

    if(this.state.robot && this.state.robot.coin_id.binance_code){
      this.intervalID = setInterval(
        () => this.fetchPrice(),
        3000
      );
    }
  }
  componentWillUnmount() {
    clearInterval(this.intervalID);
  }

  /*
  fetchPriceNew(){
    var context = this;

    $.ajax({
      url: "https://api.cryptowat.ch/markets/bitstamp/btcusd/price?apikey=BZZPXLI9QWWAMWFHR5PD",
      crossDomain : true,
      dataType: "json",
      headers: { 'Content-Type': 'application/json'},
      method: "GET",
      success: function(response) {

        let price = response.result.price;
        let price_total = 0;
        if(context.state.robot.posicao > 0){
          price_total = price * context.state.robot.posicao;
        }

        console.log(`Price 1: ${price}`);

        context.setState({
          price,
          price_total
          //lastFetch: response.time.updated
        });
      }
    });
  }
  */

  fetchPrice(){
    var context = this;
    var coin_code = this.state.robot.coin_id.binance_code;

    var api_url = `${process.env.REACT_APP_BINANCE_API_URL}?symbol=${coin_code}`;

    if(this.state.robot.corretora_id){
      api_url = `${this.state.robot.corretora_id.api_url}/api/v3/ticker/price?symbol=${coin_code}`;
    }
    
    $.ajax({
      //url: "https://api1.coindesk.com/v1/bpi/currentprice.json",
      url: api_url,
      dataType: "json",
      method: "GET",
      success: function(response) {

        //let price = response.bpi.USD.rate_float;
        let price = response.price;
        let price_total = 0;
        if(context.state.robot.posicao > 0){
          price_total = price * context.state.robot.posicao;
        }

        let variacao = '';
        
        if(context.state.last_order){
          variacao = context.calculaVariacao(price);
        }
        
        context.setState({
          price,
          price_total,
          variacao
        });
      }
    });
  }

  calculaVariacao(valor_atual){
    const valor_unitario = this.state.last_order.valor_unitario;
    const variacao = (100-(100/valor_unitario)*valor_atual)*-1;

    let classe = (variacao < 0 ? 'valor_negativo' : 'valor_positivo');

    const retorno = <span className={classe}>(
      <CurrencyFormat
      value={variacao}
      displayType={'text'} 
      decimalScale={2}
      decimalSeparator={","}
      thousandSeparator={"."}
      /> 
      %)</span>

    return retorno;
  }


  async loadItemData(id) {
    const response = await api.get('robot/' + id);
    this.setState({ robot: response.data });
    console.log(response.data);

    if(this.state.robot.posicao > 0){
      const responseLastOrder = await api.get('last_order/' + id);
      this.setState({ last_order: responseLastOrder.data });
      console.log(responseLastOrder.data);
    }

  }

  // async loadOrdens(id) {
  //   const response_ordens = await api.get('ordens/' + id);
  //   this.setState({ ordens: response_ordens.data });
  // }

  async loadOrdens(page=1, qtd=this.state.sizePerPage) {
    let url = `ordens/${this.state.robot._id}?page=${page}&qtd=${qtd}`;

    const response = await api.get(url);
    this.setState({ ordens: response.data.docs });
    this.setState({ totalDataSize: response.data.totalDocs });
  }
  async onPageChange(page, sizePerPage) {
    await this.loadOrdens(page, sizePerPage);
  }

  async onSizePerPageList(sizePerPage) {
    this.setState({ sizePerPage });
    await this.loadOrdens(2, sizePerPage);
  } 

  registerToSocket = () => {
    //const socket = io(process.env.REACT_APP_API_URL);
    socket = io(process.env.REACT_APP_API_URL, {
      // transports:['websocket', 'polling']
      transports:['websocket']
    });
    socket.on('robotUpdate', data => {
      this.setState({ robot: data });
    });
    socket.on('ordensUpdate', data => {
      this.setState({ ordens: data });
    });    
  }

	async componentDidUmount() {
    if(socket) socket.off('robotUpdate');
    if(socket) socket.off('ordensUpdate');
  }


  changeStatus = async (id, status) => {
    await api.put(`robot/status/${id}`, { ativo: status });
    await this.loadItemData(id);
  }  

  // zerar posicao - inicio
  resetPosition = async (id) => {
    let response = await api.put(`robot/reset/${id}`);
    
    if(response.data.ok === true){
      await this.loadItemData(id);
      console.log("posicao zerada com sucesso");
    } else {
      console.log(`Erro: ${response.data.msg}`);
    }
  }  

  resetPositionConfirm = (id) => {
    confirmAlert({
      title: 'Atenção!',
      message: "Confirma zera a posição? Esta ação irá vender as moedas ao preço atual.",
      buttons: [
        {
          label: 'Sim',
          onClick: () => this.resetPosition(id)
        },
        {
          label: 'Não',
          //onClick: () => alert('Click No')
          onClick: () => false
        }
      ]
    });
  }
  // zerar posicao - fim



  // cancelar ordem - inicio
  cancelOrder = async (id) => {
    let response = await api.put(`robot/cancel_order/${id}`);
    
    if(response.data.ok === true){
      await this.loadItemData(id);
      console.log("ordem cancelada com sucesso");
    } else {
      console.log(`Erro: ${response.data.msg}`);
    }
  }  
  cancelOrderConfirm = (id) => {
    confirmAlert({
      title: 'Atenção!',
      message: "Confirma o cancelamento da ordem? Esta ação irá gerar uma ordem fictícia de venda ao preço de compra.",
      buttons: [
        {
          label: 'Sim',
          onClick: () => this.cancelOrder(id),
          className: 'buttonYes'
        },
        {
          label: 'Não',
          onClick: () => false,
          className: 'buttonNo'
        }
      ]
    });
  }
  // cancelar ordem - fim

  // comprar manual - inicio
  addPosition = async (id) => {
    let response = await api.put(`robot/add_order/${id}`);
    
    if(response.data.ok === true){
      await this.loadItemData(id);
      console.log("ordem executada com sucesso");
    } else {
      console.log(`Erro: ${response.data.msg}`);
    }
  }  

  addPositionConfirm = (id) => {
    confirmAlert({
      title: 'Atenção!',
      message: "Confirma compra da moeda manualmente?",
      buttons: [
        {
          label: 'Sim',
          onClick: () => this.addPosition(id)
        },
        {
          label: 'Não',
          onClick: () => false
        }
      ]
    });
  }
  // comprar manual - fim


  render() {
    const {
      robot,
      ordens,
      variacao
    } = this.state;

    return (
      <Container fluid className="main-content-container px-6">
        { /*<MainTop 
          title='Robôs'
          icon='android'
          buttons=''
        /> */ }
        <Row noGutters className="page-header py-4">
          <PageTitle sm="4" title="Robô" icon='android' subtitle="" className="text-left" />
        </Row>

        <div className="main robot-interno">
          <RobotHeader robot={robot} />
          <br />
          <br />

          <Row>
            <Col>
              <Card className="robot-info">
                <CardHeader>
                 <h2>{robot.nome}</h2>
                ({ robot.sentido_das_ordens == 'compra_venda' ? 'compra e venda' : robot.sentido_das_ordens == 'compra' ? 'apenas compra' : 'apenas venda'})
                </CardHeader>
                <CardBody>
                  <div className="infos">
                    <div className="type">{robot.type}</div>
                    <div>{robot.gap} min.</div>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col>
              <Card className="robot-info">
                <CardHeader>
                  Modo: &nbsp;&nbsp;
                { robot.producao === 1 ? 
                  <Badge pill theme="warning">Em produção</Badge> :
                  <Badge pill theme="warning">Em teste</Badge>
                }
                <br />
                <br />
                { robot.corretora_id && <span>Corretora: {robot.corretora_id.nome } </span> }
                </CardHeader>
                <CardBody>
                { robot.tendencia === "contra" && <Badge pill theme="warning">Contra a tendência</Badge> }
                </CardBody>
              </Card>

            </Col>
            <Col className="text-right">
              <Card className="robot-info">
                <CardHeader>
                  { /*robot.ativo == 1 && <span>Rodando <Button onClick={() => this.changeStatus(robot._id, 0)} className='btStop'></Button></span>}
                  { robot.ativo == 0 && <span>Parado <Button onClick={() => this.changeStatus(robot._id, 1)} className='btStart'></Button></span> */}

                  { robot.ativo === 1 && <span>Rodando <i onClick={() => this.changeStatus(robot._id, 0)} className='btStop material-icons'>stop</i></span>}
                  { robot.ativo === 0 && <span>Parado <i onClick={() => this.changeStatus(robot._id, 1)} className='btStart material-icons'>play_arrow</i></span>}

                </CardHeader>
                <CardBody>
                  Último salvar em: <Moment format="DD/MM/YYYY HH:mm">{robot.updatedAt}</Moment>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col lg={4} md={6} xs={6}>
              <Card className="robot-info">
                <CardHeader>
                  <h3>Saldo Inicial</h3>
                </CardHeader>
                <CardBody>
                  <div className="retorno_liquido"><span className="cifrao">R$ </span>
                  <CurrencyFormat value={robot.capital_inicial} displayType={'text'} decimalScale={5} 
                    decimalSeparator={","}
                    thousandSeparator={"."} />
                  </div>
                </CardBody>
              </Card>
            </Col>

            <Col lg={4} md={6} xs={6}>
              <Card className="robot-info">
                <CardHeader>
                  <h3>Saldo Atual</h3>
                </CardHeader>
                <CardBody>
                  <div className="patrimonio"><span className="cifrao">R$ </span>
                  <CurrencyFormat value={robot.saldo_atual} displayType={'text'} decimalScale={5} 
                    decimalSeparator={","}
                    thousandSeparator={"."} />
                  </div>
                </CardBody>
              </Card>
            </Col>

            <Col lg={4} md={6} sm={12}>
              <Card className="robot-info">
                <CardHeader style={{"display":"flex", "justifyContent":"space-between"}}>
                  <h3>{robot.coin_id.binance_code}</h3>
                  <div>
                    { robot.posicao >0 && <span><Button onClick={() => this.resetPositionConfirm(robot._id)} className='btZerar'>Zerar posição</Button></span>}
                    { robot.posicao === 0 && <span><Button onClick={() => this.addPositionConfirm(robot._id)} className='btAddPosition'>Comprar</Button></span>}
                  </div>
                </CardHeader>
                <CardBody  style={{"display":"flex", "justifyContent":"space-between"}}>
                  <div>
                    <div className="drawdown">
                    <CurrencyFormat value={robot.posicao} displayType={'text'} 
                      decimalScale={5}  decimalSeparator={","} thousandSeparator={"."} />
                      </div>
                  </div>
                  <div>
                    <div className="patrimonio">
                      { /* this.state.price_total */}
                      
                      <CurrencyFormat value={this.state.price_total} displayType={'text'} 
                      decimalScale={5}  decimalSeparator={","} thousandSeparator={"."} />
                      
                    </div>
                    <div className="variacao">{ variacao }</div>
                  </div>
                </CardBody>
              </Card>
            </Col>
            
            { /*
            <Col>
              <Card className="robot-info">
                <CardHeader>
                  <h3>Drawdown máximo</h3>
                </CardHeader>
                <CardBody>
                  <div className="drawdown">38,99 %</div>
                </CardBody>
              </Card>
            </Col>
            */ }
          </Row>

          { /*
          <Row className="margin-topo">
            <Col className="col-8">
              <Card className="robot-info grafico">
                <CardHeader>
                  <h3>Evolução do patrimônio</h3>
                </CardHeader>
                <CardBody>
                  <div className="drawdown">38,99 %</div>
                </CardBody>
              </Card>
            </Col>

            <Col className="col-4">
              <Row>
                <Col>
                  <Card className="robot-info">
                    <CardHeader>
                      <h3>{robot.coin}</h3>
                    </CardHeader>
                    <CardBody>
                      <div className="valor"> {robot.valor}</div>
                      <div className="variacao"> {robot.variacao}</div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>

              <Row className="margin-topo">
                <Col>
                  <Card className="robot-info">
                    <CardHeader>
                      <h3>Drawdown máximo</h3>
                    </CardHeader>
                    <CardBody>
                      <div className="drawdown">38,99 %</div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
          */ }


          <Row className="margin-topo">
            <Col>
              <Card className="robot-info">
                <CardHeader>
                  <h3>Número de trades</h3>
                </CardHeader>
                <CardBody>
                  <div className="drawdown">{ordens.length}</div>
                </CardBody>
              </Card>
            </Col>

            {/* <Col>
              <Card className="robot-info">
                <CardHeader>
                  <h3>Trades com lucro</h3>
                </CardHeader>
                <CardBody>
                  <div className="drawdown">@TODO</div>
                  { /*
                  <div className="drawdown">70,99 %</div>
                  * / }
                </CardBody>
              </Card>
            </Col> */}

            { /*
            <Col>
              <Card className="robot-info">
                <CardHeader>
                  <h3>Fator de lucro</h3>
                </CardHeader>
                <CardBody>
                  <div className="drawdown">0,81</div>
                </CardBody>
              </Card>
            </Col>
            */ }

            { /*
            <Col>
              <Card className="robot-info">
                <CardHeader>
                  <h3>Preço atual</h3>
                </CardHeader>
                <CardBody>
                  <div className="drawdown">{ this.state.price }</div>
                </CardBody>
              </Card>
            </Col>
            */ }
            <Col>
              <Card className="robot-info">
                <CardHeader>
                  <h3>Preço atual</h3>
                </CardHeader>
                <CardBody>
                  <div className="drawdown">
                    {this.state.price}
                    { /*
                    <CurrencyFormat value={this.state.price} displayType={'text'} 
                    decimalScale={5}  decimalSeparator={","} thousandSeparator={"."} />
                    */ }
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row className="margin-topo">
            <Col>Lista de ordens</Col>
          </Row>
          <Row>
            <Col>
              <CardBody className="p-0 pb-3">
              { robot.posicao >0 && <div className="cancel_order"><Button onClick={() => this.cancelOrderConfirm(robot._id)} className='btZerar'>Cancelar Ordem</Button></div>}
              <OrdersTable 
                onPageChange={ this.onPageChange.bind(this) }
                onSizePerPageList={ this.onSizePerPageList.bind(this) } 
                // isExpandableRow={ this.isExpandableRow.bind(this) } 
                // expandComponent={ this.expandComponent.bind(this) } 
                { ...this.state }
              />
              {/* <BootstrapTable
                data={ ordens }
                condensed
                striped
                hover
                pagination
                options={ tableOptions }
                expandableRow={ this.isExpandableRow }
                expandComponent={ this.expandComponent }
              >
                <TableHeaderColumn dataField='createdAt' dataFormat={ (date) => { return <Moment format="DD/MM/YYYY HH:mm:ss">{date}</Moment> } } isKey={ true }>Data/Hora</TableHeaderColumn>
                <TableHeaderColumn dataField='tipo'>Tipo</TableHeaderColumn>
                <TableHeaderColumn dataField='qtd'>Quantidade</TableHeaderColumn>
                <TableHeaderColumn dataField='valor_unitario' dataFormat={ (value) => { return  <CurrencyFormat value={value} displayType={'text'} decimalScale={5}  decimalSeparator={","} thousandSeparator={"."} />}}>Valor unitário</TableHeaderColumn>
                <TableHeaderColumn dataField='valor' dataFormat={ (value) => { return  <CurrencyFormat value={value} displayType={'text'} decimalScale={5}  decimalSeparator={","} thousandSeparator={"."} />}}>Valor total</TableHeaderColumn>
                <TableHeaderColumn dataField='status'>Status</TableHeaderColumn>
                <TableHeaderColumn dataField='resultado' dataFormat={ this.showResultado }>Resultado</TableHeaderColumn>
                <TableHeaderColumn dataField='obs'>Obs.:</TableHeaderColumn>
              </BootstrapTable> */}

              </CardBody>
            </Col>
          </Row>


        </div>
      </Container>

    );
  }

}

export default Robot;
