import React from 'react';
import StockCodeField from "../stockcodefield/StockCodeField";
import './StockwatchAddModal.css';

import {
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  Form,
  FormInput,
  Button
} from "shards-react";

const StockwatchAddModal = (props) => {

	return (
		<div className='myModalStock'>
            <div className="modal-wrapper"
                style={{
                    transform: props.show ? 'translateY(0vh)' : 'translateY(-100vh)',
                    opacity: props.show ? '1' : '0'
                }}>
                <div className="modal-header">
                    <h3>Adicionar Ação</h3>
                    <span className="close-modal-btn" onClick={props.close}>×</span>
                </div>
                <div className="modal-body">
					<ListGroup flush>
					    <ListGroupItem className="p-3">
					      <Row>
					        <Col>
					          <Form id='stockForm'>
								<StockCodeField handleChange={props.handleChange} setCode={props.setCode}></StockCodeField>

					            <Row form>
					              <Col md="6" className="form-group">
					                <label htmlFor="feInputCity">Abaixo</label>
					                <FormInput id="fall_below" type="number" placeholder=""
										onChange={props.handleChange}  />
					              </Col>
					              <Col md="6" className="form-group">
					                <label htmlFor="feInputState">Acima</label>
					                <FormInput id="rise_above" type="number" placeholder=""
										onChange={props.handleChange} />
					              </Col>
					            </Row>

					            <Button type="button" onClick={props.add}>Adicionar</Button>
					          </Form>
					        </Col>
					      </Row>
					    </ListGroupItem>
					  </ListGroup>
				</div>

            </div>
        </div>
	)

}

export default StockwatchAddModal;
