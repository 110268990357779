import React from 'react';

//import logo from '../../assets/images/money-bag1.jpg';
//import logo from '../../assets/images/money-bag2.png';
import logo from '../../assets/images/logotipo.png';

const Logotipo = (props) => {

  var styles = {}
  if(props.width){ styles.width = props.width; }
  if(props.maxWidth){ styles.maxWidth = props.maxWidth; }
  if(props.minHeight){ styles.minHeight = props.minHeight; }

  //<img className="auth-form__logo d-table mx-auto mb-3" src="/static/media/shards-dashboards-logo.60a85991.svg" alt="Market Stocks" />
	return (
		<img className="auth-form__logo d-table mx-auto mb-3" src={logo} 
      style={styles}
      alt="Algotrading" />
	)

}

export default Logotipo;
