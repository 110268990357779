
import React from 'react';
import Logotipo from '../../components/logotipo/Logotipo';
//import { userService } from '../../services/user.service';
import api from '../../services/api';

class Register extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      name: '',
      email: '',
      password: '',
      password2: '',
      submitted: false,
      loading: false,
      ok: false,
      error: '',
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  async handleSubmit(e) {
    e.preventDefault();

    this.setState({ submitted: true });
    const { name, email, password, password2 } = this.state;

    // stop here if form is invalid
    if (!(name && email && password && password2)) {
      return;
    }

    if (password !== password2) {
      this.setState({ error: "Senha não confere", loading: false });
      return;
    }

    this.setState({ loading: true });

    await api.post("/user/register", { name, email, password })
      .then(
        ok => {
          this.setState({ loading: false, ok: true, error: '' })
        },
        error => this.setState({ error: error.response.data.message, loading: false })
      );

  }

  render() {
    const { name, email, password, password2, submitted, loading, error, ok } = this.state;
    return (
      <div className="main-content-container container-fluid px-4 my-auto h-100">

        <div className="h-100 no-gutters row">
          <div className="auth-form mx-auto my-auto col-md-5 col-lg-3">
            <div className="card">
              <div className="card-body">
                <Logotipo />
                <h5 className="auth-form__title text-center mb-4">Cadastro</h5>
                <form name="form" onSubmit={this.handleSubmit}>

                  {!ok &&
                    <div>
                      <div className={'form-group' + (submitted && !name ? ' has-error' : '')}>
                        <label htmlFor="name">Nome</label>
                        <input type="text" className="form-control" name="name" value={name} onChange={this.handleChange} />
                        {submitted && !name &&
                          <div className="help-block">Nome é obrigatório</div>
                        }
                      </div>

                      <div className={'form-group' + (submitted && !email ? ' has-error' : '')}>
                        <label htmlFor="email">E-mail</label>
                        <input type="text" className="form-control" name="email" value={email} onChange={this.handleChange} />
                        {submitted && !email &&
                          <div className="help-block">E-mail é obrigatório</div>
                        }
                      </div>

                      <div className={'form-group' + (submitted && !password ? ' has-error' : '')}>
                        <label htmlFor="password">Senha</label>
                        <input type="password" className="form-control" name="password" value={password} onChange={this.handleChange} />
                        {submitted && !password &&
                          <div className="help-block">Senha é obrigatório</div>
                        }
                      </div>

                      <div className={'form-group' + (submitted && !password ? ' has-error' : '')}>
                        <label htmlFor="password2">Confirme a senha</label>
                        <input type="password" className="form-control" name="password2" value={password2} onChange={this.handleChange} />
                        {submitted && !password2 &&
                          <div className="help-block">Confirmação de senha é obrigatório</div>
                        }
                      </div>
                    </div>
                  }

                  <div className="form-group">

                    {loading &&
                      <img alt="" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                    }
                  </div>
                  {error &&
                    <div className={'alert alert-danger'}>{error}</div>
                  }

                  {submitted && ok &&
                    <div>
                      <div className="help-block">Conta criada com sucesso.</div>
                      <div className="help-block2"><a className="ml-auto" href="/login">Clique aqui para realizar o login.</a></div>
                    </div>
                  }
                  {!ok &&
                    <button className="d-table mx-auto btn btn-accent btn-pill" disabled={loading}>Criar conta</button>
                  }
                </form>
              </div>

            </div>
            <div className="auth-form__meta d-flex mt-4">
              <a className="mx-auto" href="/login">Já é cadastrado?</a>
            </div>
          </div>
        </div>
      </div>


    );
  }
}

export default Register;
