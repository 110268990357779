import React, { Component } from 'react';
import TradingViewWidget from 'react-tradingview-widget';

import {
  Row,
  Col,
  Card,
  //CardHeader,
  CardBody,
  Container
} from "shards-react";
import PageTitle from "../../components/common/PageTitle";
import RobotHeader from "../../components/robotheader/RobotHeader";

import api from '../../services/api';

import './RobotGrafico.css';


// componente no formato de classe
class RobotGrafico extends Component {

  robot = {
    _id: '',
    nome: '',
    coin: '',
    ativo: '',
    coin_id: {}
  }

	constructor(props) {
    super(props);

		this.state = {
      code: '',
      robot: this.robot,
      ordens: []
		};
  }

	async componentDidMount () {
		const { code } = this.props.match.params;
    this.setState({ code });
    await this.loadItemData(code);
  }
  async loadItemData(id){
    const response = await api.get('robot/'+id);
    this.setState({ robot: response.data });
  }

	render(){
		const {
			//code,
      robot
		} = this.state;    

    return (
      <Container fluid className="main-content-container px-6">

        <Row noGutters className="page-header py-4">
		      <PageTitle sm="4" title="Robô" icon='android' subtitle="" className="text-left" />
		    </Row>

        <div className="main robot-interno">
          <RobotHeader robot={robot} />

          <Card className="cardRobot">
            <CardBody>
              <Row>
                <Col style={{minHeight:"600px"}}>

                <TradingViewWidget
                  symbol={robot.coin_id.binance_code}
                  //width="960"
                  //height="600"
                  autosize={true}
                  interval="60"
                  timezone="America/Sao_Paulo"
                  theme="light"
                  //style="1"
                  locale="en"
                  toolbar_bg="#f1f3f6"
                  enable_publishing={false}
                  allow_symbol_change={true}
                  studies={[
                    "IchimokuCloud@tv-basicstudies",
                    "MACD@tv-basicstudies"
                  ]}
                  show_popup_button={true}
                  popup_width="1000"
                  popup_height="650"
                  container_id="chart"
                />

                { /*
                <div class="tradingview-widget-container">
                  <div id="tradingview_113cf"></div>
                  <div class="tradingview-widget-copyright"><a href="https://www.tradingview.com/symbols/BTC/" rel="noopener" target="_blank">
                  <span class="blue-text">BTC Chart</span></a> by TradingView</div>
                  <script type="text/javascript" src="https://s3.tradingview.com/tv.js"></script>

                  <script type="text/javascript">
                  new TradingView.widget(
                  {
                    "width": 980,
                    "height": 610,
                    "symbol": "BTC",
                    "interval": "60",
                    "timezone": "America/Sao_Paulo",
                    "theme": "light",
                    "style": "1",
                    "locale": "en",
                    "toolbar_bg": "#f1f3f6",
                    "enable_publishing": false,
                    "allow_symbol_change": true,
                    "studies": [
                      "IchimokuCloud@tv-basicstudies",
                      "MACD@tv-basicstudies"
                    ],
                    "show_popup_button": true,
                    "popup_width": "1000",
                    "popup_height": "650",                
                    "container_id": "tradingview_113cf"
                  }
                  );
                  </script>                  
                </div>
               */ }

                </Col>                    
              </Row>            
            </CardBody>
          </Card>
        </div>
      </Container>

    );
	}

}

export default RobotGrafico;
