import React, { Component } from 'react';
import { Container, Row, Col, Card, CardBody } from "shards-react";
import CurrencyFormat from 'react-currency-format';
import Moment from 'react-moment';

//import io from 'socket.io-client';
import api from '../../services/api';

import PageTitle from "../../components/common/PageTitle";
import './Transaction.css';

//import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';

// componente no formato de classe
class Transaction extends Component {

  constructor(props) {
    super(props);

    this.state = {
      Transactions: [],
      BuyTotal: 0,
      ActualTotal: 0,
    };

  }

  async componentDidMount() {

    await this.loadData();
    //this.sumTotals();
    //this.registerToSocket();
  }

  async loadData() {
    const response = await api.get('transaction');
    this.setState({ Transactions: response.data });
  }

  sumTotals = () => {
    let BuyTotal = this.state.Stocks.reduce((total, stock) => total + (stock.units * stock.unit_value), 0);
    let ActualTotal = this.state.Stocks.reduce((total, stock) => total + (stock.units * stock.unit_value), 0);
    this.setState({ BuyTotal, ActualTotal });
  }

  render() {
    const {
      Transactions,
    } = this.state;

    return (
      <Container fluid className="main-content-container px-6">
        {/* Page Header */}
        <Row noGutters className="page-header py-4">
          <PageTitle sm="4" title="Histórico de Transações" subtitle="" className="text-sm-left" />
        </Row>

        <Row>
          <Col>
            <Card small className="mb-4">

              <CardBody className="p-0 pb-3">
                <table className="table mb-0">
                  <thead className="bg-light">
                    <tr>
                      <th scope="col" className="border-0">Tipo</th>
                      <th scope="col" className="border-0">Ação</th>
                      <th scope="col" className="border-0">Unidades</th>
                      <th scope="col" className="border-0">Valor por unidade</th>
                      <th scope="col" className="border-0">Valor Total</th>
                      <th scope="col" className="border-0">Data</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Transactions.map((tran, idx) => (
                      <tr key={idx}>
                        <td>{tran.type}</td>
                        <td>{tran.code}</td>
                        <td>{tran.units}</td>
                        <td><CurrencyFormat value={tran.unit_value} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'R$ '} /></td>
                        <td><CurrencyFormat value={tran.unit_value * tran.units} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'R$ '} /></td>
                        <td><Moment format="DD/MM/YYYY">{tran.date}</Moment></td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </CardBody>
            </Card>
          </Col>
        </Row>

        { /* }
     <BootstrapTable data={ Transactions } pagination options={ { noDataText: 'This is custom text for empty data' } }>
          <TableHeaderColumn dataField='type' isKey={ true }>Product ID</TableHeaderColumn>
          <TableHeaderColumn dataField='code'>Product Name</TableHeaderColumn>
          <TableHeaderColumn dataField='units'>Product Price</TableHeaderColumn>
      </BootstrapTable>
*/ }

      </Container>

    );
  }

}

export default Transaction;
