
import React from 'react';
import { Redirect } from "react-router-dom";

import { userService } from '../../services/user.service';

class Logout extends React.Component {
    constructor(props) {
        super(props);
        userService.logout();
    }

    render() {
        return (
            <Redirect to="/login" />
        );
    }
}

export default Logout;
