    // src/DownshiftTwo.js

import React, { Component } from 'react'
import Downshift from 'downshift';
import api from '../../services/api';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';

import {
  Row,
  Col,
  FormInput,
  FormGroup,
} from "shards-react"

import "./StockCodeField.css";

function renderSuggestion(suggestionProps) {
	//const { suggestion, index, itemProps, highlightedIndex, selectedItem } = suggestionProps;
	const { suggestion, index, itemProps, highlightedIndex } = suggestionProps;
	const isHighlighted = highlightedIndex === index;
	//const isSelected = (selectedItem || '').indexOf(suggestion.label) > -1;
	const isSelected = false;

	return (
		<MenuItem
			{...itemProps}
			key={suggestion.symbol}
			selected={isHighlighted}
			component="div"
			style={{
				fontWeight: isSelected ? 500 : 400,
			}}
			>
			{suggestion.symbol}&nbsp;<i className="company-name-list">{suggestion.name}</i>
		</MenuItem>
	);
}

export default class StockCodeField extends Component {

	constructor(props) {
		super(props)
		this.state = {
			code: null,
			stocks: [],
			itemName: null
		}
		this.fetchStocks = this.fetchStocks.bind(this)
		this.inputOnChange = this.inputOnChange.bind(this)
	}

	// onChange method for the input field
	inputOnChange(event) {
		if (!event.target.value) {
			return
		}
		this.fetchStocks(event.target.value)
	}
	fetchStocks(code) {
		//code = "PAG";
		api.post('/userstock/searchstock', {code: code}).then(response => {
			console.log(response.data.stocks);
			if(response.data.stocks.ResultSet.Result){
				//this.setState({ stocks: response.data.stocks.ResultSet.Result });

				// fazendo o filtro pelo getSuggestions
				const data = this.getSuggestions(response.data.stocks.ResultSet.Result, code);
				this.setState({ stocks: data });
			}
		})

	}

	getSuggestions(items, value){
		const inputValue = (value.trim()).toLowerCase();
		const inputLength = inputValue.length;
		let count = 0;
		let showEmpty = true;

		return inputLength === 0 && !showEmpty
			? []
			: items.filter(item => {
				const keep =
				count < 5 && item.symbol.slice(0, inputLength).toLowerCase() === inputValue;
				if (keep) {
					count += 1;
				}
				return keep;
			});
	};

	setData(item){
		this.setState({itemName: item.name, code: item.symbol});

		this.props.setCode(item.symbol);
	}


	render() {
        return (
	      <Row>
	        <Col>

	          <Downshift onChange={item => this.setData(item)} itemToString={item => (item ? item.symbol : '')}>

			      {({ selectedItem, getInputProps, getItemProps, highlightedIndex, isOpen, inputValue, getLabelProps }) => (
	              <div>

	                <label style={{ marginTop: '1rem', display: 'block' }} {...getLabelProps()}>Choose your favourite movie</label> <br />

					<FormInput id="code" type="text" placeholder="Código"
						{...getInputProps({
		                  onChange: this.inputOnChange
		                })}  />


					{isOpen ? (
	                  <div className="downshift-dropdown">
	                    {
							<Paper className="" square>
								{
								this.state.stocks.map((suggestion, index) =>
								renderSuggestion({
									suggestion,
									index,
									itemProps: getItemProps({ item: suggestion }),
									highlightedIndex,
									selectedItem,
								}),
							)}
							</Paper>

							/*
	                      this.state.stocks
	                        //.filter(item => !inputValue || item.label.toLowerCase().includes(inputValue.toLowerCase()))
	                        //.slice(0, 10) // return just the first ten. Helps improve performance

	                        .map((item, index) => (
	                          <div
	                            className="dropdown-item"
	                            {...getItemProps({ key: index, index, item })}
	                            style={{
	                              backgroundColor: highlightedIndex === index ? 'lightgray' : 'white',
	                              fontWeight: selectedItem === item ? 'bold' : 'normal',
	                            }}>
	                            {item.symbol}
	                          </div>
	                        ))
							*/
	                    }
	                  </div>
	                ) : null}
	              </div>
	            )}
	          </Downshift>
				<FormGroup>
					<label htmlFor="feInputAddress">Nome</label>
					<FormInput id="stock_name" type="text" readOnly="readOnly" placeholder="Nome"
					value={this.state.itemName} />
				</FormGroup>
				</Col>
			</Row>
        )
      }
    }
