import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import CurrencyFormat from 'react-currency-format';
import Moment from 'react-moment';

import { confirmAlert } from 'react-confirm-alert';
import api from '../../../services/api';

// const columns = [
//   {
//     dataField: 'createdAt',
//     text: 'Data/Hora',
//     formatter: dateFormatter
//   },
//   {
//     dataField: 'robot_name',
//     text: 'Robô'
//   },
//   {
//     dataField: 'tipo',
//     text: 'Tipo'
//   },
//   {
//     dataField: 'qtd',
//     text: 'Quantidade'
//   },
//   {
//     dataField: 'valor',
//     text: 'Valor total',
//     formatter: moneyFormatter
//   },
//   {
//     dataField: 'message',
//     text: 'Obs.:'
//   },
//   {
//     dataField: 'actions',
//     text: 'Actions',
//     isDummyField: true,
//     formatter: actionsFormatter,
//   }
// ];


const expandRow = {
  showExpandColumn: true,
  expandByColumnOnly: true,
  expandable: false,

  renderer(row){
    if(row.original){
      return <div><pre>{row.original}</pre></div>
    } else {
      return false;
    }
  },
};

function dateFormatter(value, row) {
  return (
    <Moment format="DD/MM/YYYY HH:mm:ss">{value}</Moment>
  );
}
function moneyFormatter(value, row) {
  return (
    <CurrencyFormat value={value} displayType={'text'} decimalScale={5}  decimalSeparator={","} thousandSeparator={"."} />
  );
}


export default class ErrorsTable extends React.Component {
  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);

    this.state = {
      errors: [],
      totalDataSize: 0,
      page: 1,
      sizePerPage: 30,
    };
  }
  
  async componentDidMount() {
    await this.loadItemData();
  }

  basicFormat(value){
    return (
      <div><pre>{value}</pre></div>
    )
  }
  

  // remove erro - inicio
  async removeError(id){
    let response = await api.delete(`error/${id}`);
    
    if(response.data.ok === true){
      await this.loadItemData();
      console.log("erro removido com sucesso");
    } else {
      console.log(`Erro: ${response.data.msg}`);
    }
  }  
  async removeErrorConfirm(id){
    confirmAlert({
      title: 'Atenção!',
      message: "Confirma a exclusão desse erro?",
      buttons: [
        {
          label: 'Sim',
          onClick: () => this.removeError(id),
          className: 'buttonYes'
        },
        {
          label: 'Não',
          onClick: () => false,
          className: 'buttonNo'
        }
      ]
    });
  }
  // remove erro - fim
  actionsFormatter(cell, row) {

  }
  
  async loadItemData(page=this.state.page, sizePerPage=this.state.sizePerPage, args = null) {
    let url = `error?page=${page}&qtd=${sizePerPage}`;

    if(args){ 
    }

    const response = await api.get(url);
    this.setState({ 
      errors: response.data.docs,
      totalDataSize: response.data.totalDocs,
      page,
      sizePerPage
    });

    console.log(this.state.errors)
  }


  async onPageChange(page, sizePerPage){
    this.loadItemData(page, sizePerPage);
  }

  async onSizePerPageList (sizePerPage){
    this.setState({ sizePerPage });
    await this.loadItemData(30, sizePerPage);
  }
  handleTableChange = (type, { page, sizePerPage }) => {
    // const currentIndex = (page - 1) * sizePerPage;
    this.loadItemData(page, sizePerPage);
  }


  render() {
    return (      
      <BootstrapTable
        data={this.state.errors}
        columns={[
            {
              dataField: 'createdAt',
              text: 'Data/Hora',
              formatter: dateFormatter
            },
            {
              dataField: 'robot_name',
              text: 'Robô'
            },
            {
              dataField: 'tipo',
              text: 'Tipo'
            },
            {
              dataField: 'qtd',
              text: 'Quantidade'
            },
            {
              dataField: 'valor',
              text: 'Valor total',
              formatter: moneyFormatter
            },
            {
              dataField: 'message',
              text: 'Obs.:'
            },
            {
              dataField: 'actions',
              text: 'Actions',
              isDummyField: true,
              // formatter: this.actionsFormatter,
              formatter: (cell, row) => {
                return (
                  <button onClick={()=>this.removeErrorConfirm(row._id)}>Remover</button>
                );
              }
            }
          ]
        }
        keyField='createdAt'
        striped
        condensed={false}
        hover
        // remote={true}
        remote={ { pagination: true, filter: false, sort: false } }
        fetchInfo={{ dataTotalSize: this.state.totalDataSize }}
        expandRow={ expandRow }
        options={{
          noDataText: 'Não tem erros ainda.',
          sizePerPage: this.state.sizePerPage,
          page: this.state.currentPage,
          onPageChange: this.onPageChange,
          onSizePerPageList: this.onSizePerPageList
        }}
        pagination={
          paginationFactory({
            page: this.state.currentPage,
            sizePerPage: this.state.sizePerPage,
            totalSize: this.state.totalDataSize,
          })
        }
        onTableChange={ this.handleTableChange }
      >
      </BootstrapTable>
    );
  }
}
