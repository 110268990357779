import React, { Component } from 'react';
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
} from "shards-react";

import io from 'socket.io-client';
import CurrencyFormat from 'react-currency-format';
//import io from 'socket.io-client';
import api from '../../services/api';

import SmallStats from "../../components/common/SmallStats";
import PageTitle from "../../components/common/PageTitle";
import StockAddModal from '../../components/stockaddmodal/StockAddModal';
import './UserStock.css';

// componente no formato de classe
class UserStock extends Component {

  constructor(props) {
    super(props);

    this.state = {
      Stocks: [],
      BuyTotal: 0,
      ActualTotal: 0,
      PercentTotal: 0,
      isShowing: false,
      code: null,
      name: null,
      units: null,
      unit_value: null,
      date: null
    };

  }

  componentDidMount() {
    this.startAll();
    //this.registerToSocket();
  }

  async startAll() {
    await this.loadStocks();
    this.sumTotals();
  }



  registerToSocket = () => {
    console.log("registerToSocket");
    const socket = io('http://localhost:3333');

    socket.on('stocks', stocks => {
      // adiciona o post no inicio da lista
      this.setState({ Stocks: stocks });
      //this.setState({ feed: [newPost, ...this.state.feed] });
      this.startAll();
    });

    socket.on('stockWatch', stockChange => {
      //console.log(stockChange);

      this.setState({
        Stocks: this.state.Stocks.map(stock =>
          stock.code === stockChange.symbol ? this.updateStockValues(stockChange, stock) : stock,
        )
      });
      this.sumTotals();
    });
  }

  updateStockValues(stockChange, stock) {
    stock.regularMarketPrice = stockChange.regularMarketPrice;
    stock.chartPreviousClose = stockChange.chartPreviousClose;
    stock.minMarketPrice = stockChange.minMarketPrice;
    stock.maxMarketPrice = stockChange.maxMarketPrice;
    return stock;
  }

  async loadStocks() {
    const response = await api.get('userstock');
    this.setState({ Stocks: response.data });
  }

  sumTotals = () => {

    /*
    Stocks: this.state.Stocks.map(stock =>
      this.sumStockValues(stock),
    )
    */
    let BuyTotal = this.state.Stocks.reduce((total, stock) => total + (stock.units * stock.unit_value), 0);
    //let ActualTotal = this.state.Stocks.reduce((total, stock) => total + (stock.units * stock.regularMarketPrice), 0);
    let ActualTotal = this.state.Stocks.filter(({ regularMarketPrice }) => regularMarketPrice !== undefined).reduce((total, stock) => total + (stock.units * stock.regularMarketPrice), 0);
    let PercentTotal = (ActualTotal / BuyTotal - 1) * 100;

    this.setState({ BuyTotal, ActualTotal, PercentTotal });
  }

  sumStockValues(stock) {

    stock.buy_total = stock.units * stock.unit_value;

    if (stock.regularMarketPrice) {
      stock.actual_total = stock.units * stock.regularMarketPrice;
      stock.gain_lost_value = (stock.regularMarketPrice * stock.units) - (stock.unit_value * stock.units);
      //stock.gain_list_percent = ((stock.regularMarketPrice * stock.units) / (stock.unit_value * stock.units) - 1) * 100;
      stock.gain_lost_percent = (stock.actual_total / stock.buy_total - 1) * 100;
    }
    return stock;
  }

  handleAdd = () => {
    this.setState({
      isShowing: true
    });
  }
  closeModalHandler = () => {
    this.setState({
      isShowing: false
    });
  }

  addStockhandler = async () => {
    console.log("addStockhandler");

    let stock = {
      code: this.state.code,
      units: this.state.units,
      unit_value: this.state.unit_value,
      date_buy: this.state.date,
    }
    await api.post('/userstock/buy', stock);

    this.closeModalHandler();

    await this.loadStocks();
    this.sumTotals()
  }

  handleRemove = (idx, id) => {
    api.delete(`/userstock/${id}/`);

    let array = this.state.Stocks;
    array.splice(idx, 1);
    this.setState({ Stock: array });

    this.sumTotals();
  }

  handleChange = e => {
    this.setState({ [e.target.id]: e.target.value });
  }
  setCode = code => {
    this.setState({ code });
  }
  /*
    registerToSocket = () => {
        const socket = io('http://localhost:3333');
        // backend envia 2 tipos de msgs (post e like)

        socket.on('post', newPost => {
            // adiciona o post no inicio da lista
            this.setState({ feed: [newPost, ...this.state.feed] });
        });

        socket.on('like', likedPost => {
            this.setState({
                feed: this.state.feed.map(post =>
                    post._id === likedPost._id ? likedPost : post
                )
            })
        });
    }

    handleLike = id => {
        api.post(`/stock/${id}/like`);
    }
  */


  render() {
    const {
      Stocks,
      BuyTotal,
      ActualTotal,
      PercentTotal
    } = this.state;

    return (
      <Container fluid className="main-content-container px-6">
        <StockAddModal
          className="modal"
          show={this.state.isShowing}
          close={this.closeModalHandler}
          add={this.addStockhandler}
          handleChange={this.handleChange}
          setCode={this.setCode}
          aria-labelledby="contained-modal-title-vcenter">
        </StockAddModal>

        {/* Page Header */}
        <section id='stock-list'>
          <Row noGutters className="page-header py-4">
            <PageTitle sm="4" title="Carteira Atual" subtitle="" className="text-sm-left" />
          </Row>

          <Row>

            <Col className="col-lg mb-4">
              <SmallStats
                id="small-stats-1"
                variation="1"
                label="Total de compra"
                value={<CurrencyFormat value={BuyTotal} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'R$ '} />}
		            chartData={[{ data: [] }]}
		            chartLabels={[]}
		            percentage=""
		            increase={false}
		            decrease={false}
		          />
		        </Col>

            <Col className="col-lg mb-4">
              <SmallStats
                id="small-stats-1"
                variation="1"
                label="Total Atual"
                value={<CurrencyFormat value={ActualTotal} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'R$ '} />}
		            chartData={[{ data: [] }]}
		            chartLabels={[]}
		            percentage=""
		            increase={false}
		          />
		        </Col>

            <Col className="col-lg mb-4">
              <SmallStats
                id="small-stats-1"
                variation="1"
                label="Lucro"
                value={<CurrencyFormat value={(ActualTotal - BuyTotal)} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'R$ '} />}
		            chartData={[{ data: [] }]}
		            chartLabels={[]}
		            percentage={<CurrencyFormat value={PercentTotal} displayType={'text'} decimalScale={2} thousandSeparator={true} suffix={'%'} />}
		            increase={(ActualTotal - BuyTotal) < 0 ? false : true}
		          />
		        </Col>
          </Row>

          { /*
		    <Row noGutters className="page-header py-4">
				<Col>
					Total de compra: &nbsp;<span className="total"><CurrencyFormat value={BuyTotal} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'R$ '} /></span>
				</Col>
				<Col>
					Total atual: &nbsp;<span className="total"><CurrencyFormat value={ActualTotal} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'R$ '} /></span>
				</Col>
				<Col>
					Lucro: &nbsp;<span className={(ActualTotal - BuyTotal) < 0 ? 'total negative' : 'total'}><CurrencyFormat value={ActualTotal - BuyTotal} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'R$ '} /></span>
				</Col>
				<Col>
					<div style={{textAlign:'right'}}>
						<button className="btn btn-primary btn-sm" type="button" onClick={() => this.handleAdd()}>adicionar</button>
					</div>
				</Col>
			</Row>
			*/ }

          <Row>
            <Col>
              <Card small className="mb-4">
                { /*
		          <CardHeader className="border-bottom">
		            <h6 className="m-0">Carteira</h6>
		          </CardHeader>
				  */ }
                <CardBody className="p-0 pb-3">
                  <table className="table mb-0">
                    <thead className="bg-light">
                      <tr>
                        <th scope="col" className="border-0">Ação</th>
                        <th scope="col" className="border-0">Unidades</th>
                        <th scope="col" className="border-0">Preço de compra</th>
                        <th scope="col" className="border-0">Preço atual</th>
                        <th scope="col" className="border-0">Valor Compra</th>
                        <th scope="col" className="border-0">Valor Atual</th>

                        <th scope="col" className="border-0">Ganho/Perda Valor</th>
                        <th scope="col" className="border-0">Ganho/Perda %</th>
                        <th scope="col" className="border-0">Remover</th>
                      </tr>
                    </thead>
                    <tbody>
                      {Stocks.map((stock, idx) => (
                        <tr key={idx}>
                          <td>{stock.code}</td>
                          <td>{stock.units}</td>
                          <td><CurrencyFormat value={stock.unit_value} displayType={'text'} thousandSeparator={true} prefix={'R$ '} /></td>
                          <td>{!stock.regularMarketPrice || <CurrencyFormat value={stock.regularMarketPrice} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'R$ '} />}</td>
                          <td><CurrencyFormat value={stock.buy_total} displayType={'text'} thousandSeparator={true} prefix={'R$ '} decimalScale={2} /></td>
                          <td><CurrencyFormat value={stock.actual_total} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'R$ '} /></td>
                          <td className={stock.gain_lost_value < 0 ? 'negative' : 'positive'}><CurrencyFormat value={stock.gain_lost_value} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'R$ '} /></td>
                          <td className={stock.gain_lost_percent < 0 ? 'negative' : 'positive'}><CurrencyFormat value={stock.gain_lost_percent} displayType={'text'} decimalScale={2} thousandSeparator={true} suffix={'%'} /></td>
                          <td><button className="btn btn-danger btn-sm" type="button" onClick={() => this.handleRemove(idx, stock._id)}>remover</button></td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row noGutters className="page-header py-4">
            <Col>
              <div style={{ textAlign: 'right' }}>
                <button className="btn btn-primary btn-sm" type="button" onClick={() => this.handleAdd()}>adicionar</button>
              </div>
            </Col>
          </Row>

        </section>
      </Container>

    );
  }

}

export default UserStock;
