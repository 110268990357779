import axios from 'axios';

const api = axios.create({
  // baseURL: 'http://localhost:3333',
  // baseURL: process.env.REACT_APP_API_URL,
  baseURL: process.env.REACT_APP_API_URL
});

api.interceptors.request.use((config) => {
  const token = localStorage.getItem("auth-token");
  //const token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiI1ZDcxN2VhZjliOTE1OTQyMTFhMDNiNDMiLCJpYXQiOjE1Njc3ODgzMjZ9.LK8TNeiBB_w2Y5eNIPk7Sx7hhWubT7yVhu-P9eppB0o";
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  //console.log("esta adicionando o token");
  //console.log(token);
  return config;
})

export default api;
