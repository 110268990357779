import React from "react";
import { Link } from 'react-router-dom';
import {
  Row,
  Col
} from "shards-react";

import './RobotHeader.css';

const RobotHeader = (props) => {
  var robot = props.robot;

  return (
    <Row className="topo">
      <Col><Link to={`/robot/${robot._id}`}>Sumário</Link></Col>
      <Col><Link to={`/robot-grafico/${robot._id}`}>Gráfico</Link></Col>
      <Col><Link to={`/robot-edit/${robot._id}`}>Parâmetros</Link></Col>
    </Row>
  );
};

export default RobotHeader;
