import React, { Component } from 'react';
import CurrencyFormat from 'react-currency-format';
import AlertTable from '../../components/tables/alert-table/alert-table';
import {
  Row,
  Col,
//  Card,
//  CardHeader,
  CardBody,
  Container
} from "shards-react";
import Moment from 'react-moment';

import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';

import PageTitle from "../../components/common/PageTitle";
//import RobotHeader from "../../components/robotheader/RobotHeader";

import io from 'socket.io-client';
import api from '../../services/api';

import './Alerts.css';

let socket = '';

class Alerts extends Component {

  constructor(props) {
    super(props);

    this.state = {
      alerts: [],
      totalDataSize: 0,
      actualPage: 1,
      sizePerPage: 30,
      types: {
        'ma': 'MA',
        'macd': 'MACD',
        'ema': 'EMA'
      },
      gaps: {
        1: '1 min.',
        3: '3 min.',
        5: '5 min.',
        15: '15 min.',
        30: '30 min.',
        60: '1 hora',
        120: '2 horas',
        180: '3 horas',
        240: '4 horas',
        1440: '1 dia'
      }
    };

  }

  async componentDidMount() {
    await this.loadItemData();
    this.registerToSocket();
  }

  async loadItemData(page=1, qtd=this.state.sizePerPage, args = null) {
    // const response = await api.get('alert');

    let url = `alert?page=${page}&qtd=${qtd}`;

    if(args){ 
    }

    const response = await api.get(url);
    this.setState({ alerts: response.data.docs });
    this.setState({ totalDataSize: response.data.totalDocs });
  }

  registerToSocket = () => {
    socket = io(process.env.REACT_APP_API_URL, {
      transports:['websocket', 'polling']
    });
    socket.on('alertInsert', data => {
      var alerts = [data].concat(this.state.alerts);
      this.setState({ alerts });
    });  
  }
  componentWillUnmount() {
    if(socket) socket.off('alertInsert');
  }

  async onPageChange(page, sizePerPage) {
    await this.loadItemData(page, sizePerPage);
  }

  async onSizePerPageList(sizePerPage) {
    this.setState({ sizePerPage });
    await this.loadItemData(2, sizePerPage);
  }

  render() {
    const {
      alerts,
      types,
      gaps
    } = this.state;

    return (
      <Container fluid className="main-content-container px-6">

        <Row noGutters className="page-header py-4">
          <PageTitle sm="4" title="Alertas" icon='timeline' subtitle="" className="text-left" />
        </Row>

        <div className="main alertas-interno">

          <Row>
            <Col>
              <CardBody className="p-0 pb-3">
              <AlertTable 
                onPageChange={ this.onPageChange.bind(this) }
                onSizePerPageList={ this.onSizePerPageList.bind(this) } 
                { ...this.state }
              />
              </CardBody>
            </Col>
          </Row>


        </div>
      </Container>
    );
  }

}

export default Alerts;
