import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import auth from './auth';

export default function withAuth(ComponentToProtect) {
	return class extends Component {
		constructor() {
			super();
			this.state = {
				loading: true,
				redirect: false,
			};

		}
		componentDidMount() {
			//console.log("componentDidMount");

			if(auth.isAuthenticated()){
				//console.log("usuario existe");
				this.setState({ loading: false });
			} else {
				//console.log("usuario nao existe");
				this.setState({ loading: false, redirect: true });
			}

			/*
	      fetch('/checkToken')
	        .then(res => {
				console.log("vai");
	          if (res.status === 200) {
				  console.log(res);
	            this.setState({ loading: false });
	          } else {
				  console.log("vai3");
	            const error = new Error(res.error);
	            throw error;
	          }
	        })
	        .catch(err => {
	          console.error(err);
	          this.setState({ loading: false, redirect: true });
	        });
			*/
	    }
	    render() {
			const { loading, redirect } = this.state;
			if (loading) {
				return null;
			}
			if (redirect) {
				return <Redirect to="/login" />;
			}
			return (
				<React.Fragment>
				<ComponentToProtect {...this.props} />
				</React.Fragment>
			);
	    }
	}
}
