import React from 'react';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';

import 'react-bootstrap-table/dist/react-bootstrap-table.min.css';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';

import CurrencyFormat from 'react-currency-format';
import Moment from 'react-moment';

import './order-table.css';

export default class OrdersTable extends React.Component {
  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);
  }

  showResultadoValor(cell, row){
    //return (row.tipo == 'venda' ? row.resultado : '-');
    let retorno = '-';
    if(row.tipo === 'venda'){
      retorno = <CurrencyFormat value={row.resultado} displayType={'text'} decimalScale={5}  decimalSeparator={","} thousandSeparator={"."} />
    }
    return retorno;
  }
  
  showResultadoPercentual(cell, row){
    //return (row.tipo == 'venda' ? row.resultado : '-');
    let retorno = '-';
    if(row.tipo === 'venda'){
      if(row.resultado_percentual){
        // retorno = <span>{ row.resultado_percentual  }%</span>
        retorno = <span><CurrencyFormat value={row.resultado_percentual} displayType={'text'} decimalScale={5}  decimalSeparator={","} thousandSeparator={"."} />%</span>
      } else {
        retorno = <span>-</span>
      }
    }
    return retorno;
  }

  // showOriginal(data){
  //   console.log('showOriginal');
  //   console.log(data);
  // }

  isExpandableRow(row) {
    if (row.original) return true;
    else return false;
  } 
  expandComponent(row) {
    if(row.original){

      // const data = JSON.stringify(row.original, null, 2)
      return (
        // row.original
        <div><pre>{row.original}</pre></div>
      );
    }
  }

  basicFormat(value){
    return (
      <div><pre>{value}</pre></div>
    )
  }

  render() {
    return (
      <BootstrapTable
        data={this.props.ordens}
        striped
        condensed={false}
        hover
        remote={true}
        pagination={true}
        fetchInfo={{ dataTotalSize: this.props.totalDataSize }}
        expandableRow={ this.isExpandableRow }
        expandComponent={ this.expandComponent }
        // options={ this.props.tableOptions }
        options={{
          noDataText: 'Não tem ordens ainda.',
          sizePerPage: this.props.sizePerPage,
          onPageChange: this.props.onPageChange,
          // sizePerPageList: [5, 10],
          page: this.props.currentPage,
          onSizePerPageList: this.props.onSizePerPageList
        }}
        // containerStyle={{minWidth: '100%', overflowX:'scroll'}}
      >
      <TableHeaderColumn dataField='createdAt' columnClassName='td-full-text' dataFormat={ (date) => { return <Moment format="DD/MM/YYYY HH:mm:ss">{date}</Moment> } } isKey={ true }>Data/Hora</TableHeaderColumn>
        <TableHeaderColumn dataField='status' columnClassName='td-full-text'>Status</TableHeaderColumn>
        <TableHeaderColumn dataField='tipo' columnClassName='td-full-text'>Tipo</TableHeaderColumn>
        <TableHeaderColumn dataField='valor_unitario' columnClassName='td-full-text' dataFormat={ (value) => { return  <CurrencyFormat value={value} displayType={'text'} decimalScale={5}  decimalSeparator={","} thousandSeparator={"."} />}}>Valor moeda</TableHeaderColumn>
        <TableHeaderColumn dataField='qtd' columnClassName='td-full-text'>Quantidade</TableHeaderColumn>
        <TableHeaderColumn dataField='valor' columnClassName='td-full-text' dataFormat={ (value) => { return  <CurrencyFormat value={value} displayType={'text'} decimalScale={5}  decimalSeparator={","} thousandSeparator={"."} />}}>Valor total</TableHeaderColumn>
        <TableHeaderColumn dataField='resultado' columnClassName='td-full-text' dataFormat={ this.showResultadoValor }>Margem $</TableHeaderColumn>
        <TableHeaderColumn dataField='resultado' columnClassName='td-full-text' dataFormat={ this.showResultadoPercentual }>Margem %</TableHeaderColumn>
        <TableHeaderColumn dataField='obs' columnClassName='td-full-text'>Obs.:</TableHeaderColumn>
        {/* <TableHeaderColumn dataField='obs' dataFormat={ (value) => { return this.basicFormat(value) } }>Obs.:</TableHeaderColumn> */}
      </BootstrapTable>
    );
  }
}
