import React from "react";
import {Link} from 'react-router-dom';
import CurrencyFormat from 'react-currency-format';
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
//  Button,
  Badge
} from "shards-react";


import './Robot.css';


function calcPerformance(robot){
  let valor = robot.saldo_atual - robot.capital_inicial;
  let classe = (valor < 0 ? 'valor_negativo' : 'valor_positivo');

  return (
    <CurrencyFormat className={classe} value={valor} displayType={'text'} 
    decimalScale={5} 
    decimalSeparator={","}
    thousandSeparator={"."} />
  )
}



const Robot = ( props ) => {

  var robot = props.config;

  return (
    
    <Card className="robot" key={robot._id}>
      <CardHeader>
        <span><i onClick={() => props.cloneRobotConfirm(robot._id)} className='cloneButton material-icons'>content_copy</i></span>
        <h3><Link to={`/robot/${robot._id}`}>{robot.nome}</Link></h3>
        
        {robot.coin_id && <h4>{robot.coin_id.nome}</h4> }
        ({ robot.sentido_das_ordens == 'compra_venda' ? 'compra e venda' : robot.sentido_das_ordens == 'compra' ? 'apenas compra' : 'apenas venda'})
        <div className="infos">
          <div className="type">{robot.type}</div>
          <div>{robot.gap} min.</div>
        </div>
      </CardHeader>

      <CardBody>

        <Row className="posicionado">
          <Col>
            { robot.posicao > 0 && <span className="posicao">{ robot.posicao } moeda(s)</span> }
            { robot.posicao <= 0 && <span>Não posicionado</span> }
          </Col>
        </Row>
        <Row className="valores">
          <Col>
            <div className="retorno_liquido_titulo">Saldo Atual</div>
            { /* <div className="retorno_liquido"><span className="cifrao">R$ </span>{robot.saldo_atual}</div> */ }
            <div className="retorno_liquido"><span className="cifrao">R$ </span>
            <CurrencyFormat value={robot.saldo_atual} displayType={'text'} decimalScale={5} 
            decimalSeparator={","}
            thousandSeparator={"."} /></div>
          </Col>
          <Col className="text-right">
            <div className="performance_titulo">Performance</div>
            <div className="performance"><span className="cifrao">R$ </span>
            { calcPerformance(robot) }</div>
          </Col>
        </Row>
        <Row className="status">
          <Col>
            { robot.tendencia === "contra" && <Badge pill theme="warning">Contra a tendência</Badge> }
          </Col>
          <Col>
            { /*robot.ativo == 1 && <span>Rodando <Button onClick={() => props.changeStatus(robot._id, 0)} className='btStop'></Button></span>}
            { robot.ativo == 0 && <span>Parado <Button onClick={() => props.changeStatus(robot._id, 1)} className='btStart'></Button></span> */}

            { robot.ativo === 1 && <span>Rodando <i onClick={() => props.changeStatus(robot._id, 0)} className='btStop material-icons'>stop</i></span> }
            { robot.ativo === 0 && <span>Parado <i onClick={() => props.changeStatus(robot._id, 1)} className='btStart material-icons'>play_arrow</i></span> }

          </Col>
        </Row>        
      </CardBody>
    </Card>
    

  );
};
export default Robot;
