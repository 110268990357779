import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {
  Row,
  Col,
//  Card,
//  CardHeader,
  CardBody,
  Container
} from "shards-react";
import Moment from 'react-moment';

import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';

import PageTitle from "../../components/common/PageTitle";
//import RobotHeader from "../../components/robotheader/RobotHeader";
//import io from 'socket.io-client';
import api from '../../services/api';

import './Corretoras.css';

const tableOptions = {
  noDataText: 'Não tem corretora ainda.',
  /*
  onRowClick: function(row) {
    //alert(`You click row id: ${row._id}`);
    this.goToInternalPage(row._id);
  },
  */
  /*
  onRowDoubleClick: function(row) {
    alert(`You double click row id: ${row.id}`);
  }
  */
};

class Corretoras extends Component {

  
  constructor(props) {
    super(props);

    this.state = {
      corretoras: []
    };
  }

  async componentDidMount() {
    await this.loadItemData();
  }

  async loadItemData() {
    const response = await api.get('corretora');
    this.setState({ corretoras: response.data });
  }

  getAcoes(cell, row){
    return (
      <Link to={`/corretora/${row._id}`}>
        <button className="btn btn-secondary btn-sm" type="button">Editar</button>
      </Link>
    );
  }

  render() {
    const {
      corretoras
    } = this.state;

    return (
      <Container fluid className="main-content-container px-6">

        <Row noGutters className="page-header py-4">
		      <PageTitle sm="4" title="Corretoras" icon='account_balance' subtitle="" className="text-left" />
          <Col>
            <div style={{textAlign:'right'}}>
              <Link to={`/corretora/`}>
                <button className="btn btn-secondary btn-sm" type="button">Adicionar Corretora</button>
              </Link>
            </div>
          </Col>
		    </Row>

        <div className="main corretoras-interno">

          <Row>
            <Col>
              <CardBody className="p-0 pb-3">

              <BootstrapTable data={ corretoras } striped condensed hover pagination options={ tableOptions }>
                <TableHeaderColumn dataField='updatedAt' dataFormat={ (date) => { return <Moment format="DD/MM/YYYY HH:mm:ss">{date}</Moment> } } isKey={ true }>Data/Hora</TableHeaderColumn>
                <TableHeaderColumn dataField='nome'>Nome</TableHeaderColumn>
                <TableHeaderColumn dataField='api_url'>Api URL</TableHeaderColumn>
                <TableHeaderColumn dataField='_id' dataFormat={ this.getAcoes }>Ações</TableHeaderColumn>
              </BootstrapTable>

              </CardBody>
            </Col>
          </Row>


        </div>
      </Container>

    );
  }

}

export default Corretoras;
