import React, { Component } from 'react';
import { Link, Redirect } from "react-router-dom";

import {
  Row,
  Col,
  Card,
//  CardHeader,
  CardBody,
  Container,
//  Label,
//  Form,
  FormInput,
//  FormSelect,
//  FormCheckbox,
  Alert
  
} from "shards-react";

//import Moment from 'react-moment';
//import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';

import PageTitle from "../../components/common/PageTitle";
//import RobotHeader from "../../components/robotheader/RobotHeader";
//import io from 'socket.io-client';
import api from '../../services/api';

import './Corretoras.css';

class Corretoras extends Component {


  constructor(props) {
    super(props);

    this.state = {
      currentItem: {},
      alert_visible: false,
      alert_msg: '',
      redirect: false
    };

  }
  async componentDidMount() {
    const { id } = this.props.match.params;
    if(id){
      await this.loadItemData(id);
    }
  }

  async loadItemData(id) {
    const response = await api.get('corretora/'+id);
    this.setState({ currentItem: response.data, edit: true });
  }
  alertShowHide(show, msg='') {
    this.setState({ alert_msg: msg, alert_visible: show });
  }
  saveHandler = async () =>{
    if(this.state.currentItem._id){
      api.put(`corretora/${this.state.currentItem._id}`, this.state.currentItem).then(
        data => {
          this.alertShowHide(true, 'Corretora salva com sucesso.');
        },
        error => {
          this.alertShowHide(true, 'Erro: Confira todos os campos obrigatorios');
        }
      );
    } else {
      api.post(`corretora`, this.state.currentItem).then(
        data => {
          this.alertShowHide(true, 'Corretora cadastrada com sucesso.');
        },
        error => {
          this.alertShowHide(true, 'Erro: Confira todos os campos obrigatorios');
        }
      );
    }
  }

  deleteHandler = async () =>{
    await api.delete(`corretora/${this.state.currentItem._id}`);
    this.setState({ redirect: true});
  }


  handleChange = e => {
		e.persist();

    this.setState(prevState => ({
      currentItem: { ...prevState.currentItem,  [e.target.id]: e.target.value }
    }));
	}

  render() {
    const {
      currentItem,
      redirect
    } = this.state;

    if (redirect) {
      return <Redirect to="/corretoras" />;
    }

    return (
      <Container fluid className="main-content-container px-6">

        <Row noGutters className="page-header py-4">
		      <PageTitle sm="4" title={ (currentItem._id ? "Alterar corretora" : "Adicionar corretora") } icon='account_balance' subtitle="" className="text-left" />
          <Col>
          </Col>
		    </Row>

        <div className="main corretoras-interno">

          <Row>
            <Col>
              <Card className="cardCorretora">
                <CardBody>
                  <Row>
                    <Col>
                      <h3 htmlFor="feInputCity">Nome *</h3>
                      <FormInput id="nome" type="text" placeholder=""
                        value={currentItem.nome}
                        onChange={this.handleChange} />
                    </Col>
                    <Col>
                    <h3 htmlFor="feInputCity">Api URL *</h3>
                      <FormInput id="api_url" type="text" placeholder=""
                        value={currentItem.api_url}
                        onChange={this.handleChange} />
                    </Col>                    
                  </Row>       
                  <br />
                  <br />              
                  {/* <Row>
                    <Col>
                      <h3 htmlFor="feInputCity">Username</h3>
                      <FormInput id="username" type="text" placeholder=""
                        value={currentItem.username}
                        onChange={this.handleChange} />
                    </Col>
                    <Col>
                    <h3 htmlFor="feInputCity">Password</h3>
                      <FormInput id="password" type="text" placeholder=""
                        value={currentItem.password}
                        onChange={this.handleChange} />
                    </Col>                    
                  </Row>
                  <br />
                  <br />               */}
                  <Row>
                    <Col>
                      <h3 htmlFor="feInputCity">Api Key</h3>
                      <FormInput id="api_key" type="text" placeholder="Só preencha se quiser mudar"
                        onChange={this.handleChange} />
                    </Col>                  
                  </Row>  
                  <br />
                  <br />   
                  <Row>
                    <Col>
                      <h3 htmlFor="feInputCity">Secret</h3>
                      <FormInput id="api_secret" type="text" placeholder="Só preencha se quiser mudar"
                        onChange={this.handleChange} />
                    </Col>                  
                  </Row>                   
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Card>
            <CardBody>
              <Row noGutters>
                <Col>
                <Alert dismissible={(e) => this.alertShowHide(false)} open={this.state.alert_visible}>
                  { this.state.alert_msg }
                </Alert>
                </Col>
              </Row>
              <Row noGutters>
                <Col>
                  <div style={{display:'flex', justifyContent: 'space-between'}}>
                    <button className="btn btn-primary btn-sm" type="button" onClick={() => this.deleteHandler()}>Excluir corretora</button>

                    <div>
                      <Link to={`/corretoras`}>
                        <button className="btn btn-primary btn-sm" type="button">Voltar</button>
                      </Link>
                      &nbsp;&nbsp;&nbsp;
                      <button className="btn btn-primary btn-sm" type="button" onClick={() => this.saveHandler()}>Salvar</button>
                    </div>
                  </div>
                </Col>
              </Row>
            
            </CardBody>
          </Card>

        </div>
      </Container>

    );
  }

}

export default Corretoras;
