import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {
  Row,
  Col,
//  Card,
//  CardHeader,
  CardBody,
  Container
} from "shards-react";
import Moment from 'react-moment';

import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';

import PageTitle from "../../components/common/PageTitle";
//import RobotHeader from "../../components/robotheader/RobotHeader";
//import io from 'socket.io-client';
import api from '../../services/api';

import './Users.css';

const tableOptions = {
  noDataText: 'Não tem user ainda.',
  /*
  onRowClick: function(row) {
    //alert(`You click row id: ${row._id}`);
    this.goToInternalPage(row._id);
  },
  */
  /*
  onRowDoubleClick: function(row) {
    alert(`You double click row id: ${row.id}`);
  }
  */
};

class Users extends Component {

  
  constructor(props) {
    super(props);

    this.state = {
      users: []
    };
  }

  async componentDidMount() {
    await this.loadItemData();
  }

  async loadItemData() {
    const response = await api.get('user');
    this.setState({ users: response.data });
  }

  getAcoes(cell, row){
    return (
      <Link to={`/user/${row._id}`}>
        <button className="btn btn-secondary btn-sm" type="button">Editar</button>
      </Link>
    );
  }

  render() {
    const {
      users
    } = this.state;

    return (
      <Container fluid className="main-content-container px-6">

        <Row noGutters className="page-header py-4">
		      <PageTitle sm="4" title="Users" icon='assessment' subtitle="" className="text-left" />
          <Col>
            <div style={{textAlign:'right'}}>
              <Link to={`/user/`}>
                <button className="btn btn-secondary btn-sm" type="button">Adicionar Usuário</button>
              </Link>
            </div>
          </Col>
		    </Row>

        <div className="main users-interno">

          <Row>
            <Col>
              <CardBody className="p-0 pb-3">

              <BootstrapTable data={ users } striped condensed hover pagination options={ tableOptions }>
                <TableHeaderColumn dataField='updatedAt' dataFormat={ (date) => { return <Moment format="DD/MM/YYYY HH:mm:ss">{date}</Moment> } } isKey={ true }>Data/Hora</TableHeaderColumn>
                <TableHeaderColumn dataField='name'>Nome</TableHeaderColumn>
                <TableHeaderColumn dataField='email'>E-mail</TableHeaderColumn>
                <TableHeaderColumn dataField='_id' dataFormat={ this.getAcoes }>Ações</TableHeaderColumn>
              </BootstrapTable>

                { /* 
                <table className="table mb-0">
                  <thead className="bg-light">
                    <tr>
                      <th scope="col" style={{ width: '10%' }} className="border-1">Data/Hora</th>
                      <th scope="col" style={{ width: '10%' }} className="border-1">Coin</th>
                      <th scope="col" style={{ width: '10%' }} className="border-1">Tipo</th>
                      <th scope="col" style={{ width: '10%' }} className="border-1">Valor unitário</th>
                      <th scope="col" style={{ width: '10%' }} className="border-1">Obs.:</th>
                    </tr>
                  </thead>
                  <tbody>
                    {users.map((item, idx) => (
                      <tr key={idx}>
                        <td><Moment format="DD/MM/YYYY HH:mm:ss">{item.updatedAt}</Moment></td>
                        <td>{item.code}</td>
                        <td>{item.action}</td>
                        <td>{item.price}</td>
                        <td>{item.obs}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                    */ }
              </CardBody>
            </Col>
          </Row>


        </div>
      </Container>

    );
  }

}

export default Users;
