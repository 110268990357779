import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { Col } from "shards-react";

const PageTitle = ({ icon, title, subtitle, className, ...attrs }) => {
  const classes = classNames(
    className,
    "page-title"
  );

  return (
    <Col xs="12" sm="12" className={classes} { ...attrs }>
      { icon && <i className="material-icons top-icon">{icon}</i> }
      <div className="title">{title}</div>
      <span className="text-uppercase page-subtitle">{subtitle}</span>
    </Col>
  )
};

PageTitle.propTypes = {

  icon: PropTypes.string,
  /**
   * The page title.
   */
  title: PropTypes.string,
  /**
   * The page subtitle.
   */
  subtitle: PropTypes.string
};

export default PageTitle;
